import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { useResourceContext } from "react-admin";
import { exportNestedList } from "$components/List/ListActions";
import NestedListData from "$components/NestedList/NestedListData";
import { NestedList } from "$components/NestedList/NestedList";
const UsersContribution = (props) => {
    const resourceName = useResourceContext();
    return (_jsx(NestedList, { subCollection: "UsersContribution", searchField: "rewardKey", resource: "GroupRewards", ...props, exporter: exportNestedList("GroupRewardsUsersContribution"), children: _jsx(NestedListData, { model: "RewardUserContribution", resourceName: resourceName }) }));
};
export { UsersContribution };
