import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useRecordContext } from "react-admin";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
export const ProgressField = ({ sourceValue, sourceTotal, ...props }) => {
    const record = useRecordContext(props);
    if (!record?.[sourceTotal])
        return null;
    const total = record[sourceTotal];
    const val = record[sourceValue] > total ? total : record[sourceValue] ?? 0;
    return (_jsxs("div", { children: [_jsxs(Typography, { sx: { position: "absolute", zIndex: 1, color: "white" }, children: [val, " / ", total] }), _jsx(LinearProgress, { variant: "determinate", value: (val / total) * 100, sx: { height: "1.5rem" } })] }));
};
