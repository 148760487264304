import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { PhotoshopPicker, SwatchesPicker } from "react-color";
import { useInput, useRecordContext } from "react-admin";
import { ColorFieldContainer, ColorFieldPreview } from "./ColorPicker.styled";
import * as material from "@mui/material/colors";
const colorPalette = [
    [
        material.red["900"],
        material.red["800"],
        material.red["700"],
        material.red["600"],
        material.red["500"]
    ],
    [
        material.pink["900"],
        material.pink["700"],
        material.pink["600"],
        material.pink["500"],
        material.pink["400"]
    ],
    [
        material.purple["900"],
        material.purple["700"],
        material.purple["600"],
        material.purple["500"],
        material.purple["400"]
    ],
    [
        material.deepPurple["900"],
        material.deepPurple["700"],
        material.deepPurple["600"],
        material.deepPurple["500"],
        material.deepPurple["400"]
    ],
    [
        material.indigo["900"],
        material.indigo["700"],
        material.indigo["600"],
        material.indigo["500"],
        material.indigo["400"]
    ],
    [
        material.blue["900"],
        material.blue["700"],
        material.blue["600"],
        material.blue["500"],
        material.blue["400"]
    ],
    [
        material.lightBlue["900"],
        material.lightBlue["700"],
        material.lightBlue["600"],
        material.lightBlue["500"],
        material.lightBlue["400"]
    ],
    [
        material.cyan["900"],
        material.cyan["700"],
        material.cyan["600"],
        material.cyan["500"],
        material.cyan["400"]
    ],
    [
        material.teal["900"],
        material.teal["700"],
        material.teal["600"],
        material.teal["500"],
        material.teal["400"]
    ],
    [
        material.green["900"],
        material.green["800"],
        material.green["700"],
        material.green["600"],
        material.green["500"]
    ],
    [
        material.lightGreen["900"],
        material.lightGreen["700"],
        material.lightGreen["600"],
        material.lightGreen["500"],
        material.lightGreen["400"]
    ],
    [
        material.lime["900"],
        material.lime["700"],
        material.lime["600"],
        material.lime["500"],
        material.lime["400"]
    ],
    [
        material.yellow["900"],
        material.yellow["800"],
        material.yellow["700"],
        material.yellow["600"],
        material.yellow["500"]
    ],
    [
        material.amber["900"],
        material.amber["700"],
        material.amber["600"],
        material.amber["500"],
        material.amber["400"]
    ],
    [
        material.orange["900"],
        material.orange["700"],
        material.orange["600"],
        material.orange["500"],
        material.orange["400"]
    ],
    [
        material.deepOrange["900"],
        material.deepOrange["700"],
        material.deepOrange["600"],
        material.deepOrange["500"],
        material.deepOrange["400"]
    ],
    [
        material.brown["900"],
        material.brown["700"],
        material.brown["600"],
        material.brown["500"],
        material.brown["400"]
    ],
    [
        material.blueGrey["900"],
        material.blueGrey["700"],
        material.blueGrey["600"],
        material.blueGrey["500"],
        material.blueGrey["400"]
    ]
];
export const ColorInput = (props) => {
    const record = useRecordContext();
    const { label, source } = props;
    const recordColor = record?.[source] ?? "#FFFFFF";
    const { field: input } = useInput({ defaultValue: recordColor, ...props, type: "text" });
    const handleColorReset = useCallback(() => input.onChange(recordColor), [record]);
    return (_jsxs("div", { style: { display: "flex", flexWrap: "wrap" }, children: [_jsx(PhotoshopPicker, { header: label ?? source, color: input.value, onCancel: handleColorReset, onChangeComplete: ({ hex }) => {
                    input.onChange(hex);
                } }), _jsx(SwatchesPicker
            // height and width the same as photoshop picker
            , { 
                // height and width the same as photoshop picker
                height: "317px", width: "513px", styles: {
                    default: {
                        body: { padding: "30px 0 0px 34px" },
                        overflow: { overflow: "hidden" }
                    }
                }, disableAlpha: true, color: input.value, colors: colorPalette, onCancel: handleColorReset, onChangeComplete: ({ hex }) => {
                    input.onChange(hex);
                } })] }));
};
export const ColorField = ({ source, cellClassName }) => {
    const record = useRecordContext();
    return (_jsxs(ColorFieldContainer, { children: [_jsx(ColorFieldPreview, { style: { backgroundColor: record[source] ?? "#FFFFFF00" } }), _jsx("span", { className: cellClassName, children: record[source] })] }));
};
