import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useRecordContext, useUpdate, useRefresh, useResourceContext } from "react-admin";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { ConditionalModifyButtonContent } from "$components/List/ListActions/ConditionalModifyButton/ConditionalModifyButton.styled";
export const ConditionalModifyButton = ({ tooltip, changedData, icon, isDisabled, tooltipPlacement = "hover" }) => {
    const resource = useResourceContext();
    const record = useRecordContext();
    const refresh = useRefresh();
    const data = typeof changedData === "function" ? changedData(record) : changedData;
    const [update, { isLoading }] = useUpdate(resource, { id: record.id, data, previousData: record });
    const formattedLabel = typeof tooltip === "function" ? tooltip(record) : tooltip;
    if (tooltipPlacement === "hover") {
        return (_jsx(Tooltip, { title: formattedLabel, disableInteractive: true, children: _jsx(Button, { size: "medium", startIcon: typeof icon === "function" ? icon(record) : icon, onClick: () => update().then(refresh), disabled: isLoading ||
                    (typeof isDisabled === "function" ? isDisabled(record) : isDisabled) }) }));
    }
    return (_jsx(Button, { size: "medium", startIcon: typeof icon === "function" ? icon(record) : icon, onClick: () => update().then(refresh), disabled: isLoading || (typeof isDisabled === "function" ? isDisabled(record) : isDisabled), children: _jsx(ConditionalModifyButtonContent, { children: formattedLabel }) }));
};
