/**
 * Function that makes sure the parsed row meets the requirements of the model definition
 * @param {entity: IMetaData[IModelNames]} entity Model metadata definitions
 * @param {ITransformedRow} csvRowItem CSV data where keys are columns and values are respective cells from the parsed row
 * @returns {Promise<void>} Promise that resolve when validation was successful and rejects otherwise
 */
export function validateRow(entity, csvRowItem) {
    /* Cannot properly validate the data if entity description is missing from the API */
    const { __defaultValues, ...entityFields } = entity;
    /* Make sure there are no unwanted keys */
    if (!Object.keys(csvRowItem).every((key) => ["createdate", "createdby", "lastupdate", "updatedby"].includes(key) ||
        entityFields.hasOwnProperty(key))) {
        return Promise.reject("Unwanted keys in the imported data.");
    }
    /* Make sure all keys have valid types */
    if (!Object.entries(csvRowItem).every(([key, val]) => {
        /* Optional property */
        if (!val) {
            return true;
        }
        const { type } = entityFields[key];
        /* Check model types */
        if (!type) {
            // TODO: add checks for model types to verify if referenced models do exist
            return true;
        }
        /* Check array types */
        if ((entityFields[key].isArray || type === "Array") && !Array.isArray(val)) {
            return false;
        }
        /* Check enum types */
        if (Array.isArray(type) && !type.includes(val)) {
            return false;
        }
        /* Check string based types */
        if (typeof type === "string" &&
            (type.toLowerCase() === "string" || type === "richText") &&
            typeof val !== "string") {
            return false;
        }
        /* Check date and number types */
        // casting date to number returns timestamp that's NaN for invalid dates
        if ((type === "Date" || type === "Number") && isNaN(Number(val))) {
            return false;
        }
        /* Check boolean types */
        if (type === "Boolean" && typeof val !== "boolean") {
            return false;
        }
        return true;
    })) {
        return Promise.reject("Invalid data types.");
    }
    return Promise.resolve();
}
const cellTransformDict = {
    Date: (key, val) => [key, new Date(val)],
    Boolean: (key, val) => [key, val === "true"],
    Number: (key, val) => [key, Number(val)],
    Array: (key, val) => [key, val.split(";")]
};
/**
 * Function transforming the data from imported csv file into a format defined by the model
 * @param {entity: IMetaData[IModelNames]} entity Model metadata definitions
 * @param {Record<string, string>} row CSV data where keys are columns and values are respective cells from the row
 * @returns {ITransformedRow} Formatted data
 */
export function transformRow(entity, row) {
    const transformedRow = Object.fromEntries(Object.entries(row)
        .filter(([key]) => entity.hasOwnProperty(key))
        .map(([key, val]) => {
        const { type, isArray } = entity[key];
        if (!val)
            return [key, val];
        if (isArray) {
            return cellTransformDict.Array(key, val);
        }
        return cellTransformDict[type]?.(key, val) ?? [key, val];
    }));
    return {
        ...entity.__defaultValues,
        ...transformedRow
    };
}
