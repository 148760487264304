import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BulkExportButton, Datagrid, EditButton, List, NumberField, TextField } from "react-admin";
import ListFilter from "$components/List/ListFilter";
import { exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { AvatarField } from "$components/TableCells/UserCell";
import React from "react";
export const Users = (props) => {
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("Users"), filters: _jsx(ListFilter, { source: "username", placeholder: "User name" }), ...props, children: _jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsx(BulkExportButton, {}), children: [_jsx(EditButton, { label: "", size: "medium" }), _jsx(AvatarField, { source: "profilePicture", label: "Avatar" }), _jsx(TextField, { source: "username" }), _jsx(NumberField, { source: "level" }), _jsx(NumberField, { source: "coins" }), _jsx(NumberField, { source: "points" })] }) }));
};
