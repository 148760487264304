import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useGetList } from "react-admin";
import Badge from "@mui/material/Badge";
import FolderStoreIcon from "@mui/icons-material/RuleFolder";
import FolderStoreOpen from "@mui/icons-material/RuleFolderOutlined";
export const StoreManagementIcon = ({ isOpen }) => {
    const { data: privateItems, isLoading: isPrivateLoading } = useGetList("StoreClaimedPrivateItems", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "claimedDate", order: "DESC" },
        filter: { processed: false, refunded: false }
    });
    const { data: publicItems, isLoading: isPublicLoading } = useGetList("StorePublicItems", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "gatheredDate", order: "DESC" },
        filter: { processed: false }
    });
    const itemsCounts = (publicItems?.filter(({ gatheredDate }) => Boolean(gatheredDate)).length ?? 0) +
        (privateItems?.length ?? 0);
    return (_jsx(Badge, { sx: { "& .MuiBadge-badge": { fontSize: 12, height: 15, minWidth: 15 } }, badgeContent: itemsCounts, color: "error", invisible: isPrivateLoading || isPublicLoading, max: 9, children: isOpen ? _jsx(FolderStoreOpen, {}) : _jsx(FolderStoreIcon, {}) }));
};
