import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useCreatePath } from "ra-core";
import { Button, List, TopToolbar, useResourceContext } from "react-admin";
import { Pagination } from "$components/Pagination";
import { Link } from "react-router-dom";
import ContentAdd from "@mui/icons-material/Add";
import { ExportButton } from "ra-ui-materialui";
import { exporter, ImportData } from "$components/List/ListActions";
import { ValueBitTagsDatagrid } from "./ValueBitTags.datagrid";
import { ValueBitTagsEmpty } from "./ValueBitTags.empty";
export const ValueBitTags = () => {
    const createPath = useCreatePath();
    const resource = useResourceContext();
    return (_jsx(List, { filter: { type: "VALUE_BIT_TAG" }, pagination: _jsx(Pagination, {}), empty: _jsx(ValueBitTagsEmpty, {}), actions: _jsxs(TopToolbar, { children: [_jsx(Button, { color: "primary", component: Link, variant: "text", label: "ra.action.create", to: {
                        pathname: createPath({
                            resource: `${resource}/ValueBitTags`,
                            type: "create"
                        }),
                        search: `?source=${JSON.stringify({ type: "VALUE_BIT_TAG" })}`
                    }, children: _jsx(ContentAdd, {}) }), _jsx(ExportButton, {}), _jsx(ImportData, {})] }), exporter: exporter("ValueBitTags"), children: _jsx(ValueBitTagsDatagrid, {}) }));
};
