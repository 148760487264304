import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from "react";
import { AutocompleteInput, useInfiniteGetList, useResourceContext } from "react-admin";
import uniqBy from "lodash.uniqby";
export const TemplateCategoryInput = () => {
    const resource = useResourceContext();
    const { isLoading, data: currentTemplates } = useInfiniteGetList(resource, { sort: { order: "ASC", field: "templateCategory" } });
    const [userCreatedCategories, setUserCreatedCategories] = useState([]);
    const categoriesFromTemplates = uniqBy(currentTemplates?.pages.flatMap(({ data }) => data) ?? [], "templateCategory").map(({ templateCategory }) => ({
        id: templateCategory,
        name: templateCategory
    }));
    return (_jsx(AutocompleteInput, { disabled: isLoading, choices: [...userCreatedCategories, ...categoriesFromTemplates], source: "templateCategory", name: "templateCategory", onCreate: (test) => {
            const newCategory = { id: test, name: test };
            setUserCreatedCategories((categories) => [...categories, newCategory]);
            return newCategory;
        }, fullWidth: true }));
};
