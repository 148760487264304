import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { useGetResourceLabel, useResourceContext, useResourceDefinition, useTranslate } from "ra-core";
import Inbox from "@mui/icons-material/Inbox";
import Typography from "@mui/material/Typography";
import { CreateButton, EmptyClasses } from "ra-ui-materialui";
import { ImportData } from "$components/List/ListActions";
import { ListEmptyContainer } from "./ListEmpty.styled";
export const ListEmpty = (props) => {
    const { className } = props;
    const { hasCreate } = useResourceDefinition(props);
    const resource = useResourceContext(props);
    const translate = useTranslate();
    const getResourceLabel = useGetResourceLabel();
    const resourceName = translate(`resources.${resource}.forcedCaseName`, {
        smart_count: 0,
        _: getResourceLabel(resource, 0)
    });
    const emptyMessage = translate("ra.page.empty", { name: resourceName });
    const inviteMessage = translate("ra.page.invite");
    return (_jsxs(ListEmptyContainer, { className: className, children: [_jsxs("div", { className: EmptyClasses.message, children: [_jsx(Inbox, { className: EmptyClasses.icon }), _jsx(Typography, { variant: "h4", paragraph: true, children: translate(`resources.${resource}.empty`, {
                            _: emptyMessage
                        }) }), hasCreate && (_jsx(Typography, { variant: "body1", children: translate(`resources.${resource}.invite`, {
                            _: inviteMessage
                        }) }))] }), hasCreate && (_jsxs("div", { className: EmptyClasses.toolbar, children: [_jsx(ImportData, { ...props, variant: "contained" }), _jsx(CreateButton, { variant: "contained" })] }))] }));
};
