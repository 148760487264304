import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BulkExportButton, BulkUpdateButton, Datagrid, EditButton, TextField, useListContext } from "react-admin";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ColorField } from "$components/ColorPicker";
import { ConditionalModifyButton } from "$components/List/ListActions/ConditionalModifyButton";
import { ApplyTemplateButton } from "$components/List/ListActions/ApplyTemplateButton";
import { BulkApplyTemplate } from "$components/List/ListBulkActions/BulkApplyTemplate";
export const CategoriesDataGrid = ({ isTemplate }) => {
    const { filterValues } = useListContext();
    return (_jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsxs(_Fragment, { children: [_jsx(BulkUpdateButton, { label: "Expose", data: { isHidden: false }, icon: _jsx(VisibilityOffIcon, {}), mutationMode: "pessimistic" }), _jsx(BulkUpdateButton, { label: "Hide", data: { isHidden: true }, icon: _jsx(VisibilityIcon, {}), mutationMode: "pessimistic" }), _jsx(BulkExportButton, {}), isTemplate && _jsx(BulkApplyTemplate, { appliedResource: "Categories" })] }), children: [_jsx(EditButton, { label: "", size: "medium" }), isTemplate && _jsx(ApplyTemplateButton, { appliedResource: "Categories" }), isTemplate && _jsx(TextField, { source: "templateCategory" }), _jsx(TextField, { source: "type" }), _jsx(TextField, { source: "name" }), (!filterValues.type ||
                filterValues.type === "BADGE" ||
                filterValues.type === "VALUE_BIT_TAG") && _jsx(ColorField, { source: "color" }), _jsx(ConditionalModifyButton, { source: "visibility", label: "Visibility", tooltipPlacement: "content", tooltip: (record) => (record["isHidden"] ? "Show" : "Hide"), icon: null, changedData: (record) => ({ isHidden: !record["isHidden"] }) })] }));
};
