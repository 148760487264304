import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Create, Edit, SimpleForm, TextInput, BooleanInput, required, NumberInput, AutocompleteInput, Toolbar, SaveButton } from "react-admin";
import metadata from "$public/metadata.json";
import { RichTextInput } from "ra-input-rich-text";
import { CategoriesFormFields } from "$components/Entities/Categories/CategoriesFormFields";
import { ReferenceCreateInput, TemplateCategoryInput } from "$components/Inputs";
import { TaskTypeInput, TaskTagInput } from "./inputs";
export const TasksEdit = ({ variant, variantProps, isTemplate }) => {
    const FormWrapper = variant === "create" ? Create : Edit;
    const { __defaultValues = {} } = metadata.Task;
    return (_jsx(FormWrapper, { ...variantProps, children: _jsxs(SimpleForm, { defaultValues: __defaultValues, toolbar: _jsx(Toolbar, { children: _jsx(SaveButton, {}) }), children: [isTemplate && _jsx(TemplateCategoryInput, {}), _jsx(TextInput, { source: "name", name: "name", fullWidth: true, validate: required() }), _jsx(RichTextInput, { source: "description", name: "description", fullWidth: true, height: 40 }), _jsx(TaskTypeInput, {}), _jsx(ReferenceCreateInput, { label: "Category", source: "categoryKey", reference: isTemplate ? "CategoriesTemplates" : "Categories", name: "categoryKey", dialogChildren: _jsx(CategoriesFormFields, { predefinedType: "TASK", isTemplate: isTemplate }), filter: { type: "TASK", isHidden: false }, children: _jsx(AutocompleteInput, { label: "Category", optionText: "name", filterToQuery: (value) => ({ name: value }), fullWidth: true }) }), _jsx(NumberInput, { source: "points", name: "points", validate: required() }), _jsx(NumberInput, { source: "dailyLimit", name: "dailyLimit" }), _jsx(NumberInput, { source: "weeklyLimit", name: "weeklyLimit" }), _jsx(NumberInput, { source: "monthlyLimit", name: "monthlyLimit" }), _jsx(TextInput, { source: "iconUrl", name: "iconUrl", fullWidth: true }), _jsx(BooleanInput, { source: "isHidden", name: "isHidden", label: "Hide", options: {} }), _jsx(BooleanInput, { source: "isSentToChatFeed", name: "isSentToChatFeed", label: "Chat feed promotion", helperText: "Submitting this task will also send information to the integrated chat feed", options: {} }), _jsx(TaskTagInput, {})] }) }));
};
