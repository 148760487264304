import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Edit, SimpleForm, NumberInput, SaveButton, Create } from "react-admin";
import metadata from "$public/metadata.json";
import { HiddenInput } from "$components/Inputs";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Toolbar from "@mui/material/Toolbar";
export const CoinLevelSettingsEdit = ({ variant, variantProps }) => {
    const FormWrapper = variant === "create" ? Create : Edit;
    const { __defaultValues = {} } = metadata.CoinLevelSettings;
    return (_jsx(FormWrapper, { ...variantProps, redirect: null, children: _jsxs(SimpleForm, { defaultValues: __defaultValues, toolbar: _jsx(Toolbar, { children: _jsx(SaveButton, {}) }), children: [_jsx(HiddenInput, { source: "id", value: "SETTINGS" }), _jsx(CardHeader, { title: "Coins / Lvl settings" }), _jsx(CardContent, { children: _jsx(NumberInput, { source: "coinsPer250Points", name: "coinsPer250Points", label: "Coins per 250 points" }) })] }) }));
};
