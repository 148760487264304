import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useResourceContext } from "react-admin";
import { NestedList } from "$components/NestedList/NestedList";
import { IndividualChallengesListData } from "./IndividualChallengesListData";
import { exportNestedList } from "$components/List/ListActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
const IndividualChallenges = (props) => {
    const resourceName = useResourceContext();
    return (_jsx(NestedList, { searchField: "username", subCollection: "IndividualChallenges", resource: "Users", ...props, exporter: exportNestedList("UsersIndividualChallenges"), empty: _jsx(CardContent, { children: _jsx(Typography, { children: "Choose the user" }) }), children: _jsx(IndividualChallengesListData, { model: "UserIndividualChallenge", resourceName: resourceName }) }));
};
export { IndividualChallenges };
