import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SimpleForm, Create, EditContextProvider, useGetOne, useUpdate, useResourceContext, Title } from "react-admin";
import * as pluralize from "pluralize";
import { cloneElement } from "react";
import { renderFields } from "$components/List/EditAndCreateBase";
import { metadata } from "$utils/metadata.utils";
import { useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
export const NestedEdit = (props) => {
    const navigate = useNavigate();
    const resource = useResourceContext();
    const [update, { isLoading: saving }] = useUpdate();
    const { id: fullDocumentPath } = useParams();
    const id = fullDocumentPath.substring(fullDocumentPath.lastIndexOf("/") + 1);
    const absoluteCollectionPath = fullDocumentPath.substring(0, fullDocumentPath.lastIndexOf("/"));
    const parentDocumentId = absoluteCollectionPath.substring(absoluteCollectionPath.indexOf("/") + 1, absoluteCollectionPath.lastIndexOf("/"));
    const save = (data) => update(absoluteCollectionPath, { id, data }, { onSuccess: () => navigate(`/${resource}?id=${parentDocumentId}`) });
    const { isLoading, data: record, refetch } = useGetOne(absoluteCollectionPath, { id });
    const defaultTitle = `Edit ${fullDocumentPath}`;
    return (_jsxs(EditContextProvider, { value: {
            record,
            isLoading,
            save,
            saving,
            resource,
            defaultTitle,
            isFetching: isLoading,
            redirect: `/${resource}`,
            refetch
        }, children: [_jsx(Title, { title: defaultTitle }), _jsx(Card, { sx: { marginTop: "1em" }, children: _jsx(Stack, { children: cloneElement(props.children, {
                        record,
                        redirect: `/${resource}`,
                        resource,
                        version: 0
                    }) }) })] }));
};
// const NestedCreate = (props: CreateProps & {children: ReactElement}) => {
//     const id = props.id.substring(props.id.lastIndexOf("/") + 1);
//     const absoluteCollectionPath = props.id.substring(0, props.id.lastIndexOf("/"));
//
//     const redirectFn = useRedirect();
//     const dataProvider = useDataProvider();
//     const [record, setRecord] = useState(undefined);
//     const [saving, setSaving] = useState(false);
//
//     const controllerProps: EditControllerProps = {
//         basePath: props.basePath,
//         defaultTitle: absoluteCollectionPath + id,
//         loaded: Boolean(record),
//         loading: !Boolean(record),
//         onFailureRef: undefined,
//         onSuccessRef: undefined,
//         record,
//         redirect: `/${props.resource}`,
//         refetch(): void {
//             setRecord(undefined);
//             dataProvider.getOne(absoluteCollectionPath, {id})
//                 .then(({data}) => setRecord(data));
//         },
//         resource: "UserIndividualRewards",
//         save(data, redirectTo, callbacks): void {
//             setSaving(true);
//             dataProvider.update(absoluteCollectionPath, {id, data, previousData: record})
//                 .then((res) => {
//                     setSaving(false);
//                     redirectFn(redirectTo, basePath, data.id, data);
//                 });
//         },
//         saving: saving,
//         setOnFailure(onFailure): void {
//         },
//         setOnSuccess(onSuccess): void {
//         },
//         setTransform(transform): void {
//         },
//         transformRef: undefined,
//         version: 0
//     };
//
//     useEffect(() => {
//         dataProvider.getOne(absoluteCollectionPath, {id})
//             .then(({data}) => setRecord(data));
//     }, [dataProvider]);
//
//     const {
//         basePath, defaultTitle, redirect, resource, save, version
//     } = controllerProps;
//
//     return (
//         <EditContextProvider value={{...controllerProps, record}}>
//             <div>
//                 <h1>{defaultTitle}</h1>
//                 {cloneElement(props.children, {
//                     basePath,
//                     record,
//                     redirect,
//                     resource,
//                     save,
//                     saving,
//                     version: 0
//                 })}
//             </div>
//         </EditContextProvider>
//     );
// };
const NestedEditAndCreateBase = ({ variant, variantProps }) => {
    const FormWrapper = variant === "create" ? Create : NestedEdit;
    const entityName = useResourceContext();
    const { __defaultValues = {}, ...currentEntity } = entityName === "UsersPoints"
        ? metadata.UserPoints
        : metadata[pluralize.singular(entityName)] ?? {};
    return (_jsx(FormWrapper, { ...variantProps, children: _jsx(SimpleForm, { defaultValues: __defaultValues, children: renderFields(currentEntity) }) }));
};
export { NestedEditAndCreateBase };
