import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { required, SelectInput } from "react-admin";
import { nonValueBitCategoryTypeChoices } from "$constants/categoryTypeChoices";
export const CategoryTypeInput = () => {
    const { watch, resetField } = useFormContext();
    const inputValue = watch("type");
    // Resetting the color, priority fields when category type changes,
    // since this field is only available for badge categories
    useEffect(() => {
        resetField("color");
        resetField("priority");
    }, [inputValue]);
    return (_jsx(SelectInput, { name: "type", placeholder: "type", source: "type", choices: nonValueBitCategoryTypeChoices, validate: required() }));
};
