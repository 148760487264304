import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { TextInput } from "react-admin";
import { DefaultEditorOptions, RichTextInput } from "ra-input-rich-text";
import { useFormContext } from "react-hook-form";
export const RichTextWithPlainCopy = ({ source, sourcePlain = `${source}Plain` }) => {
    const { setValue } = useFormContext();
    return (_jsxs(_Fragment, { children: [_jsx(RichTextInput, { source: source, editorOptions: {
                    ...DefaultEditorOptions,
                    onUpdate: ({ editor }) => setValue(sourcePlain, editor.getText())
                }, fullWidth: true }), _jsx(TextInput, { source: sourcePlain, fullWidth: true, disabled: true, multiline: true })] }));
};
