import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import * as pluralize from "pluralize";
import { metadata } from "$utils/metadata.utils";
import { CellComponent } from "./CellComponent";
export function renderTextFields(resource) {
    const entity = getMetaDataEntity(resource) || { __defaultValues: {} };
    const { __defaultValues = {}, ...currentEntity } = entity;
    const fields = Object.entries(currentEntity);
    // KK: I have hidden the info on updates/creations for now
    // fields.push(
    //     ["updatedby", {source: "updatedby", type: "string"}],
    //     ["lastupdate", {source: "lastupdate", type: "string"}],
    //     ["createdby", {source: "createdby", type: "string"}],
    //     ["createdate", {source: "createdate", type: "string"}]
    // );
    return fields.map(([key, value]) => {
        return _jsx(CellComponent, { source: key, keyName: key, value: value }, key);
    });
}
/**
 * Function extracting the entity from metadata based on the resource
 * @param {string} resource Name of the resource
 * @returns {IMetaData[IModelNames] | void} metadata entity or void if no such resource exists
 */
export function getMetaDataEntity(resource) {
    const modelName = pluralize.singular(resource);
    return resource === "UsersPoints" ? metadata.UserPoints : metadata[modelName];
}
