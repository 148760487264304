import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import List from "@mui/material/List";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import { useRemoveFromStore, useSidebarState, useStore, useTranslate } from "react-admin";
const SubMenu = (props) => {
    const { name, icon, children, openedIcon, parentname = "root" } = props;
    const storeKey = `menu.${parentname}.open`;
    const translate = useTranslate();
    const [sidebarIsOpen] = useSidebarState();
    const [openedMenuItem, setOpenedMenuItem] = useStore(storeKey, "");
    const removeFromStore = useRemoveFromStore();
    const isOpen = openedMenuItem === name;
    const header = (_jsxs(MenuItem, { onClick: () => handleMenuClick(), children: [_jsx(ListItemIcon, { style: { minWidth: "40px" }, children: isOpen ? openedIcon ? openedIcon : _jsx(ExpandIcon, {}) : icon }), _jsx(Typography, { variant: "inherit", color: "textSecondary", children: name })] }));
    const arrayChildren = Array.isArray(children) ? children : [children];
    const handleMenuClick = () => (isOpen ? removeFromStore(storeKey) : setOpenedMenuItem(name)); // closes or opens self
    const handleMenuClose = () => removeFromStore(`menu.${name}.open`); // closes children
    return (_jsxs("div", { children: [sidebarIsOpen ? header : _jsx(Tooltip, { title: translate(name), children: header }), _jsx(Collapse, { in: isOpen, timeout: "auto", unmountOnExit: true, onExit: handleMenuClose, children: _jsx(List, { disablePadding: true, style: { paddingLeft: sidebarIsOpen ? 4 : 0 }, children: arrayChildren.map((child, id) => React.cloneElement(child, { parentname: name, key: child.props.name ?? id })) }) })] }));
};
export default SubMenu;
