import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Create, Edit, ReferenceInput, SimpleForm, TextInput, NumberInput, DateTimeInput, AutocompleteInput, AutocompleteArrayInput } from "react-admin";
import { useFormContext } from "react-hook-form";
import metadata from "$public/metadata.json";
import format from "date-fns/format";
import { Timestamp } from "firebase/firestore";
import { RichTextWithPlainCopy } from "$components/Inputs/RichTextWithPlainCopy";
const DateHashInput = ({ isCreate }) => {
    const form = useFormContext();
    const inputValue = form.watch("finishDate");
    useEffect(() => {
        const date = inputValue instanceof Timestamp ? inputValue.toDate() : new Date(inputValue);
        form.setValue("yearHash", format(date, "yyyy"));
        form.setValue("monthHash", format(date, "yyyy-MMM").toUpperCase());
        form.setValue("weekHash", format(date, "yyyy-ww"));
        form.setValue("dayHash", format(date, "yyyy-MMM-dd").toUpperCase());
    }, [inputValue]);
    return _jsx(DateTimeInput, { name: "finishDate", source: "finishDate", disabled: !isCreate, required: true });
};
export const ValueBitsEdit = ({ variant, variantProps }) => {
    const isCreate = variant === "create";
    const FormWrapper = isCreate ? Create : Edit;
    const { __defaultValues = {} } = metadata.ValueBit;
    const now = new Date();
    __defaultValues["finishDate"] = now.toISOString();
    __defaultValues["yearHash"] = format(now, "yyyy");
    __defaultValues["monthHash"] = format(now, "yyyy-MMM").toUpperCase();
    __defaultValues["weekHash"] = format(now, "yyyy-ww");
    __defaultValues["dayHash"] = format(now, "yyyy-MMM-dd").toUpperCase();
    return (_jsx(FormWrapper, { ...variantProps, children: _jsxs(SimpleForm, { defaultValues: __defaultValues, children: [_jsx(TextInput, { name: "title", source: "title", required: true, fullWidth: true }), _jsx(ReferenceInput, { name: "tags", source: "tags", reference: "Categories", filter: { type: "VALUE_BIT_TAG" }, children: _jsx(AutocompleteArrayInput, { optionText: "name", filterToQuery: (q) => ({ name: q }), fullWidth: true }) }), _jsx(TextInput, { name: "link", source: "link", fullWidth: true }), _jsx(NumberInput, { name: "score", source: "score", disabled: true, fullWidth: true }), _jsx(RichTextWithPlainCopy, { source: "note" }), _jsx(ReferenceInput, { source: "userId", reference: "Users", required: true, children: _jsx(AutocompleteInput, { label: "User", optionText: "username", filterToQuery: (q) => ({ username: q }), fullWidth: true }) }), _jsx(DateHashInput, { isCreate: isCreate }), _jsx(ReferenceInput, { label: "Task", source: "taskId", name: "taskId", reference: "Tasks", required: true, children: _jsx(AutocompleteInput, { optionText: "name", fullWidth: true, disabled: !isCreate, filterToQuery: (q) => ({ name: q }) }) })] }) }));
};
