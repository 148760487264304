import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { FilterList, FilterListItem, FilterLiveSearch } from "react-admin";
import { CategoryFilter } from "$components/CategoryFilter";
import { FilterSidebar, FilterSidebarTemplateCategory } from "$components/FilterSidebar";
import { challengeTypeChoices } from "$constants/challengeTypeChoices";
import { challengeStatusChoices } from "$constants/challengeStatusChoices";
export const ChallengesSidebar = ({ isTemplate }) => {
    return (_jsxs(FilterSidebar, { children: [_jsx(FilterLiveSearch, { name: "name", source: "name", label: "Name" }), isTemplate && _jsx(FilterSidebarTemplateCategory, {}), _jsx(FilterList, { label: "Challenge type", icon: "", children: challengeTypeChoices.map((choice) => (_jsx(FilterListItem, { label: choice.name, value: { type: choice.id } }, choice.id))) }), _jsx(FilterList, { label: "Challenge status", icon: "", children: challengeStatusChoices.map((choice) => (_jsx(FilterListItem, { label: choice.name, value: { status: choice.id } }, choice.id))) }), _jsx(CategoryFilter, { source: "categoryKey", type: "CHALLENGE", isTemplate: isTemplate })] }));
};
