import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { useResourceContext } from "react-admin";
import { NestedList } from "$components/NestedList/NestedList";
import { CoinLevelsListData } from "$components/Entities/CoinLevels/CoinLevelsListData";
import { exportNestedList } from "$components/List/ListActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
const CoinMonthlyChallenges = (props) => {
    const resourceName = useResourceContext();
    return (_jsx(NestedList, { searchField: "name", subCollection: "CoinMonthlyChallenges", resource: "CoinLeaderboards", empty: _jsx(CardContent, { children: _jsx(Typography, { children: "Choose the coin tracker period" }) }), ...props, exporter: exportNestedList("CoinLeaderboardsCoinMonthlyChallenges"), children: _jsx(CoinLevelsListData, { model: "CoinMonthlyChallenges", resourceName: resourceName }) }));
};
export { CoinMonthlyChallenges };
