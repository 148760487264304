import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BulkExportButton, BulkUpdateButton, Datagrid, EditButton, List, TextField } from "react-admin";
import { DeleteItemButton, exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { ConditionalModifyButton } from "$components/List/ListActions/ConditionalModifyButton";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import NotificationsIcon from "@mui/icons-material/Notifications";
export const ChatFeedIntegrations = (props) => {
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("ChatFeedIntegrations"), ...props, children: _jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsxs(_Fragment, { children: [_jsx(BulkUpdateButton, { label: "Disable", data: { enabled: false }, icon: _jsx(NotificationsOffIcon, {}), mutationMode: "pessimistic" }), _jsx(BulkUpdateButton, { label: "Enable", data: { enabled: true }, icon: _jsx(NotificationsIcon, {}), mutationMode: "pessimistic" }), _jsx(BulkExportButton, {})] }), children: [_jsx(EditButton, { label: "", size: "medium" }), _jsx(DeleteItemButton, {}), _jsx(ConditionalModifyButton, { source: "enabled", label: "Status", tooltipPlacement: "content", tooltip: (record) => (record["enabled"] ? "Disable" : "Enable"), icon: (record) => record["enabled"] ? _jsx(NotificationsOffIcon, {}) : _jsx(NotificationsIcon, {}), changedData: (record) => ({ enabled: !record["enabled"] }) }), _jsx(TextField, { source: "name" }), _jsx(TextField, { source: "integrationType" })] }) }));
};
