import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BulkExportButton, BulkUpdateButton, Datagrid, DateField, EditButton, List, NumberField, ReferenceField, TextField } from "react-admin";
import { exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ConditionalModifyButton } from "$components/List/ListActions/ConditionalModifyButton";
import { ChallengesSidebar } from "$components/Entities/Challenges/Challenges.sidebar";
import { ApplyTemplateButton } from "$components/List/ListActions/ApplyTemplateButton";
import { BulkApplyTemplate } from "$components/List/ListBulkActions/BulkApplyTemplate";
const Challenges = ({ isTemplate, ...props }) => {
    const references = {
        categoryKey: {
            appliedResource: "Categories",
            templateResource: "CategoriesTemplates"
        },
        rewardBadge: {
            appliedResource: "Badges",
            templateResource: "BadgesTemplates",
            references: {
                rankKey: {
                    appliedResource: "Categories",
                    templateResource: "CategoriesTemplates"
                }
            }
        },
        qualifyingTasks: {
            appliedResource: "Tasks",
            templateResource: "TasksTemplates",
            references: {
                categoryKey: {
                    appliedResource: "Categories",
                    templateResource: "CategoriesTemplates"
                }
            }
        },
        requiresCompletion: {
            appliedResource: "Challenges",
            templateResource: "ChallengesTemplates"
        }
    };
    references.requiresCompletion.references = references;
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("Challenges"), aside: _jsx(ChallengesSidebar, { isTemplate: isTemplate }), ...props, children: _jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsxs(_Fragment, { children: [_jsx(BulkUpdateButton, { label: "Hide", data: { status: "HIDDEN" }, icon: _jsx(VisibilityIcon, {}), mutationMode: "pessimistic" }), _jsx(BulkUpdateButton, { label: "Activate", data: { status: "ACTIVE" }, icon: _jsx(VisibilityOffIcon, {}), mutationMode: "pessimistic" }), _jsx(BulkExportButton, {}), isTemplate && (_jsx(BulkApplyTemplate, { appliedResource: "Challenges", references: references }))] }), children: [_jsx(EditButton, { label: "", size: "medium" }), isTemplate && (_jsx(ApplyTemplateButton, { appliedResource: "Challenges", references: references })), isTemplate && _jsx(TextField, { source: "templateCategory" }), _jsx(ReferenceField, { label: "category", reference: isTemplate ? "CategoriesTemplates" : "Categories", source: "categoryKey", children: _jsx(TextField, { source: "name" }) }), _jsx(TextField, { source: "name" }), _jsx(NumberField, { source: "goalPoints" }), _jsx(DateField, { source: "endDate" }), _jsx(TextField, { source: "status" }), _jsx(ConditionalModifyButton, { source: "visibility", label: "Visibility", tooltip: (record) => (record["status"] === "HIDDEN" ? "Show" : "Hide"), tooltipPlacement: "content", icon: null, changedData: (record) => ({
                        status: record["status"] === "HIDDEN" ? "ACTIVE" : "HIDDEN"
                    }), isDisabled: (record) => record["status"] !== "HIDDEN" && record["status"] !== "ACTIVE" })] }) }));
};
export { Challenges };
