import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useGetList } from "react-admin";
import ClaimedItemIcon from "@mui/icons-material/LocalGroceryStore";
import Badge from "@mui/material/Badge";
export const StoreClaimedPrivateItemsIcon = () => {
    const { data: unprocessedItems, isLoading } = useGetList("StoreClaimedPrivateItems", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "claimedDate", order: "DESC" },
        filter: { processed: false, refunded: false }
    });
    return (_jsx(Badge, { sx: { "& .MuiBadge-badge": { fontSize: 12, height: 15, minWidth: 15 } }, badgeContent: unprocessedItems?.length, color: "error", invisible: isLoading, max: 9, children: _jsx(ClaimedItemIcon, {}) }));
};
