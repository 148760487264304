export var ScalingStrategy;
(function (ScalingStrategy) {
    ScalingStrategy["CROP"] = "CROP";
    ScalingStrategy["EXTEND"] = "EXTEND";
    ScalingStrategy["IGNORE_ASPECT_RATIO"] = "IGNORE_ASPECT_RATIO";
    ScalingStrategy["CENTER_WITHOUT_SCALING"] = "CENTER_WITHOUT_SCALING";
    ScalingStrategy["MANUAL"] = "MANUAL";
})(ScalingStrategy || (ScalingStrategy = {}));
export async function scaleImageBeforeUpload(file, dimensions, strategy) {
    // ensure the file is an image
    if (!file.type.match(/image.*/))
        return null;
    const image = new Image();
    image.src = URL.createObjectURL(file);
    await new Promise((res) => (image.onload = res));
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d", { alpha: true });
    if (!context)
        return null; // there is an edge case that getContext is called before full DOM load returning null context
    canvas.width = dimensions.width;
    canvas.height = dimensions.height;
    const widthRatio = canvas.width / image.width;
    const heightRatio = canvas.height / image.height;
    switch (strategy) {
        // manual scaling is processed as part of the input so here ignore aspect ratio
        case ScalingStrategy.MANUAL:
        case ScalingStrategy.IGNORE_ASPECT_RATIO:
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
            break;
        case ScalingStrategy.CENTER_WITHOUT_SCALING:
            context.drawImage(image, (canvas.width - image.width) / 2, (canvas.height - image.height) / 2);
            break;
        case ScalingStrategy.CROP:
            if (widthRatio > heightRatio) {
                const scaledHeight = widthRatio * image.height;
                context.drawImage(image, 0, (canvas.height - scaledHeight) / 2, canvas.width, scaledHeight);
            }
            else {
                const scaledWidth = widthRatio * image.height;
                context.drawImage(image, (canvas.width - scaledWidth) / 2, 0, scaledWidth, canvas.height);
            }
            break;
        case ScalingStrategy.EXTEND:
            if (image.height <= image.width) {
                const scaledWidth = heightRatio * image.width;
                context.drawImage(image, (canvas.width - scaledWidth) / 2, 0, scaledWidth, canvas.height);
            }
            else {
                const scaledHeight = widthRatio * image.height;
                context.drawImage(image, 0, (canvas.height - scaledHeight) / 2, canvas.width, scaledHeight);
            }
            break;
    }
    return new Promise((res) => canvas.toBlob(res)).then((blob) => new File([blob], file.name));
}
