import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { AutocompleteArrayInput, ReferenceArrayInput } from "react-admin";
export const TaskTagInput = () => {
    const { watch, resetField } = useFormContext();
    const inputValue = watch("taskType");
    // Resetting the valueFeedAutoTag field when task type changes,
    // since that field is only relevant to the Value Feed type Tasks
    useEffect(() => {
        resetField("valueFeedAutoTag");
    }, [inputValue]);
    if (inputValue !== "VALUE_FEED")
        return _jsx(_Fragment, {});
    return (_jsx(ReferenceArrayInput, { name: "valueFeedAutoTag", source: "valueFeedAutoTag", reference: "Categories", filter: { isHidden: false, type: "VALUE_BIT_TAG" }, children: _jsx(AutocompleteArrayInput, { label: "Tags", filterToQuery: (q) => ({
                isHidden: false,
                name: q,
                type: "VALUE_BIT_TAG"
            }), optionText: (record) => record.name ?? record.id, fullWidth: true, clearOnBlur: false, helperText: "Tags automatically added to the Value Bit" }) }));
};
