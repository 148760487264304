import { jsx as _jsx } from "react/jsx-runtime";
import { DateInput, NumberInput, BooleanInput, TextInput, ImageInput, ImageField } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import { parse, endOfDay, format } from "date-fns";
import { fromUTC, toUTC } from "$utils/toUTC";
import { ColorInput } from "$components/ColorPicker";
function getInputComponent(type, source) {
    const normalizedType = type.toLowerCase();
    if (normalizedType === "date") {
        return (props) => (_jsx(DateInput, { ...props, parse: (value) => value && toUTC(endOfDay(parse(value, "yyyy-MM-dd", new Date()))), format: (data) => {
                if (!data) {
                    return "";
                }
                if (data instanceof Date) {
                    return format(fromUTC(data), "yyyy-MM-dd");
                }
                if (/^\d{4}-\d{2}-\d{2}$/.test(data)) {
                    return data;
                }
                return format(new Date(data), "yyyy-MM-dd");
            } }));
    }
    else if (normalizedType === "boolean") {
        return BooleanInput;
    }
    else if (source.match(/color/gi)) {
        return ColorInput;
    }
    else if (normalizedType === "richtext") {
        return RichTextInput;
    }
    else if (normalizedType === "number") {
        return NumberInput;
    }
    else if (normalizedType === "image") {
        return (props) => (_jsx(ImageInput, { accept: "image/png, image/jpeg, image/jpg", ...props, children: _jsx(ImageField, { source: "src", title: "image" }) }));
    }
    else if (source === "description") {
        return (props) => _jsx(TextInput, { multiline: true, minRows: 5, ...props });
    }
    else {
        return TextInput;
    }
}
export { getInputComponent };
