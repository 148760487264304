import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { required, SelectInput } from "react-admin";
import { taskTypeChoices } from "$constants/taskTypeChoices";
export const TaskTypeInput = () => {
    const typeField = "taskType";
    const { watch, resetField } = useFormContext();
    const inputValue = watch(typeField);
    // Resetting the requireComment field when task type changes,
    // since that field has been integrated into task type in the newest releases
    useEffect(() => {
        resetField("requireComment");
    }, [inputValue]);
    return (_jsx(SelectInput, { name: typeField, placeholder: typeField, source: typeField, choices: taskTypeChoices, validate: required() }));
};
