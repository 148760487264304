import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { List } from "react-admin";
import { query, where } from "firebase/firestore";
import { exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { CategoriesSidebar } from "./Categories.sidebar";
import { CategoriesDataGrid } from "./Categories.datagrid";
export const Categories = ({ isTemplate = false, ...props }) => {
    return (_jsx(List, { filter: {
            collectionQuery: (ref) => query(ref, where("type", "!=", "VALUE_BIT_TAG"))
        }, pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("Categories"), aside: _jsx(CategoriesSidebar, { isTemplate: isTemplate }), ...props, children: _jsx(CategoriesDataGrid, { isTemplate: isTemplate }) }));
};
