import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { BulkDeleteButton, BulkExportButton, Datagrid, EditButton, ReferenceField, TextField, useRecordContext, WrapperField } from "react-admin";
import { BadgePreview } from "$components/Entities/Badges/BadgePreview";
import { BadgeTitle } from "$components/Entities/Badges/BadgeTitle";
import { BadgeContainer } from "./BadgeGrid.styled";
import { ConditionalModifyButton } from "$components/List/ListActions/ConditionalModifyButton";
import { ApplyTemplateButton } from "$components/List/ListActions/ApplyTemplateButton";
import { BulkApplyTemplate } from "$components/List/ListBulkActions/BulkApplyTemplate";
const BadgeCard = () => {
    const record = useRecordContext();
    return (_jsxs(BadgeContainer, { children: [_jsx(BadgePreview, { iconUrl: record.iconUrl, isLocked: true }), _jsx(BadgePreview, { iconUrl: record.iconUrl })] }));
};
export const BadgesGrid = ({ isTemplate }) => {
    const references = {
        rankKey: {
            appliedResource: "Categories",
            templateResource: "CategoriesTemplates"
        }
    };
    return (_jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsxs(_Fragment, { children: [_jsx(BulkExportButton, {}), _jsx(BulkDeleteButton, {}), isTemplate && (_jsx(BulkApplyTemplate, { appliedResource: "Badges", references: references }))] }), children: [_jsx(EditButton, { label: "", size: "medium" }), _jsx(ConditionalModifyButton, { icon: null, tooltip: ({ isHidden }) => (isHidden ? "Show" : "Hide"), tooltipPlacement: "content", changedData: ({ isHidden }) => ({ isHidden: !isHidden }) }), isTemplate && _jsx(ApplyTemplateButton, { appliedResource: "Badges", references: references }), isTemplate && _jsx(TextField, { source: "templateCategory" }), _jsx(WrapperField, { label: "Title", sortBy: "title", children: _jsx(BadgeTitle, { isTemplate: isTemplate }) }), _jsx(ReferenceField, { label: "Rank", reference: isTemplate ? "CategoriesTemplates" : "Categories", source: "rankKey", children: _jsx(TextField, { source: "name" }) }), _jsx(WrapperField, { label: "Preview", children: _jsx(BadgeCard, {}) })] }));
};
