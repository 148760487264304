import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Login, LoginForm } from "react-admin";
import { getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { StyledFirebaseAuth } from "./StyledFirebaseAuth";
const uiConfig = {
    signInSuccessUrl: "#/",
    signInOptions: [GoogleAuthProvider.PROVIDER_ID, new OAuthProvider("microsoft.com").providerId]
};
const CustomLoginPage = (props) => {
    return (_jsx(Login, { ...props, children: _jsxs("div", { children: [_jsx(LoginForm, { ...props }), _jsx(StyledFirebaseAuth, { uiConfig: uiConfig, firebaseAuth: getAuth() })] }) }));
};
export default CustomLoginPage;
