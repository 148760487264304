import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";
import ListItemIcon from "@mui/material/ListItemIcon";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import MaMenuItem from "@mui/material/MenuItem";
import { Confirm } from "react-admin";
import DialogContentText from "@mui/material/DialogContentText";
export const BackupButton = () => {
    const backupTrigger = httpsCallable(getFunctions(getApp(), "europe-central2"), "callBackup");
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleConfirm = () => {
        setIsLoading(true);
        backupTrigger({ ping: true }).then(() => {
            setIsConfirmationOpen(false);
            setIsLoading(false);
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs(MaMenuItem, { onClick: () => setIsConfirmationOpen(true), children: [_jsx(ListItemIcon, { style: { minWidth: "40px" }, children: _jsx(SaveIcon, {}) }), _jsx(Typography, { variant: "inherit", color: "textSecondary", children: "Create Backup" })] }), _jsx(Confirm, { isOpen: isConfirmationOpen, title: "Create backup of the entire database - not only this gamespace?", content: _jsxs(_Fragment, { children: [_jsx(DialogContentText, { sx: { marginBottom: "8px" }, children: "Are you sure you want to create a backup of all gamespaces within the project?" }), _jsx(DialogContentText, { sx: { marginBottom: "8px" }, children: "Operation you are about to perform is extremely important. It involves importing the data of the entire project. It may cause application unavailability." }), _jsx(DialogContentText, { children: "Perform at own risk." })] }), onConfirm: handleConfirm, loading: isLoading, onClose: () => setIsConfirmationOpen(false) })] }));
};
