import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { NestedList } from "$components/NestedList/NestedList/NestedList";
import { exportNestedList } from "$components/List/ListActions";
import { UserPointsListData } from "./UserPointsListData";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
export const UserPoints = (props) => (_jsx(NestedList, { resource: "Leaderboards", searchProps: {
        sort: { field: "endDate", order: "DESC" }
    }, searchField: "name", subCollection: "UsersPoints", empty: _jsx(CardContent, { children: _jsx(Typography, { children: "Choose the leaderboard" }) }), ...props, sort: { field: "points", order: "DESC" }, exporter: exportNestedList("LeaderboardsUserPoints"), children: _jsx(UserPointsListData, { model: "UsersPoints", resourceName: "UsersPoints" }) }));
