import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Edit, SimpleForm, SaveButton, Create, TextInput } from "react-admin";
import metadata from "$public/metadata.json";
import Toolbar from "@mui/material/Toolbar";
import { HiddenInput } from "$components/Inputs";
export const AppConfigsEdit = ({ variant, variantProps }) => {
    const FormWrapper = variant === "create" ? Create : Edit;
    const { __defaultValues = {} } = metadata.AppConfig;
    return (_jsx(FormWrapper, { ...variantProps, redirect: null, children: _jsxs(SimpleForm, { defaultValues: __defaultValues, toolbar: _jsx(Toolbar, { children: _jsx(SaveButton, {}) }), children: [_jsx(HiddenInput, { source: "id", value: "COINFIG" }), _jsx(TextInput, { source: "unsubscribePaymentLink" })] }) }));
};
