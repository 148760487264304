import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";
/**
 * Function that initializes firebase
 * @returns {Promise<{adminOptions: RAFirebaseOptions, config: IFirebaseConfig}>} configs for react-admin-firebase data and auth providers
 */
export function initializeFirebaseService() {
    console.log("initializeFirebaseService");
    const isDev = process.env.NODE_ENV?.startsWith("development") ?? false; // development or development_emulated
    const firebaseAdminOptions = (app) => ({
        app,
        logging: isDev,
        // metaFieldCasing: "camel",
        // disableMeta: false,
        rootRef: () => {
            const gamespace = window.location.pathname.substring(1);
            if (!gamespace)
                throw "Trying to access non existing gamespace";
            return `/Apps/${gamespace}`;
        },
        transformToDb: (resourceName, documentData) => {
            // remove undefined fields from the document, since firestore does not accept undefined as a field value
            return Object.fromEntries(Object.entries(documentData).filter(([, val]) => val !== undefined));
        }
    });
    if (isDev) {
        const config = {
            projectId: "emulated-project",
            appId: "emulated-project",
            apiKey: "SG.emulated-project",
            authDomain: "emulated-project.firebaseapp.com",
            storageBucket: "emulated-project"
        };
        const app = initializeApp(config);
        connectStorageEmulator(getStorage(), "localhost", 9199);
        connectFirestoreEmulator(getFirestore(), "localhost", 8080);
        connectAuthEmulator(getAuth(), "http://localhost:9099");
        connectFunctionsEmulator(getFunctions(app, "europe-central2"), "localhost", 5001);
        return Promise.resolve({
            adminOptions: firebaseAdminOptions(app),
            config
        });
    }
    return fetch("/__/firebase/init.json")
        .then((res) => res.json())
        .then((config) => ({
        adminOptions: firebaseAdminOptions(initializeApp(config)),
        config
    }));
}
