import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BooleanInput, FormDataConsumer, NumberInput, required, TextInput } from "react-admin";
import { CategoryTypeInput } from "$components/Entities/Categories/Inputs";
import { ColorInput } from "$components/ColorPicker";
import { TemplateCategoryInput } from "$components/Inputs";
export const CategoriesFormFields = ({ predefinedType, isTemplate }) => {
    return (_jsxs(_Fragment, { children: [isTemplate && _jsx(TemplateCategoryInput, {}), predefinedType ? (_jsx(TextInput, { name: "type", placeholder: "type", source: "type", disabled: true, fullWidth: true, defaultValue: predefinedType })) : (_jsx(CategoryTypeInput, {})), _jsx(TextInput, { name: "name", source: "name", validate: required(), fullWidth: true }), _jsx(FormDataConsumer, { children: ({ formData: { type } }) => {
                    if (type === "VALUE_BIT_TAG") {
                        return _jsx(ColorInput, { name: "color", source: "color" });
                    }
                    if (type === "BADGE") {
                        return (_jsxs(_Fragment, { children: [_jsx(ColorInput, { name: "color", source: "color" }), _jsx(NumberInput, { name: "priority", source: "priority", helperText: "Defines the order of badges in profile view." +
                                        " The smaller the value the higher the badge." })] }));
                    }
                    return null;
                } }), _jsx(BooleanInput, { source: "isHidden", name: "isHidden", options: {} })] }));
};
