import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ChipField, Datagrid, DateField, EditButton, List, ReferenceField, SingleFieldList, TextField, ReferenceArrayField, NumberField, ReferenceInput, AutocompleteInput } from "react-admin";
import { DeleteItemButton, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { ListBulkActions } from "$components/List/ListBulkActions";
import { UserCell } from "$components/TableCells/UserCell";
export const ValueBits = (props) => {
    return (_jsx(List, { empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), sort: { field: "finishDate", order: "DESC" }, filters: [
            _jsx(ReferenceInput, { label: "User", source: "userId", reference: "Users", alwaysOn: true, children: _jsx(AutocompleteInput, { optionText: "username", filterToQuery: (q) => ({ username: q }), sx: { width: "300px" } }) })
        ], ...props, children: _jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsx(ListBulkActions, {}), children: [_jsx(EditButton, { label: "", size: "medium" }), _jsx(DeleteItemButton, { customConfirmTitle: "Are you sure you want to delete this item?", customConfirmContent: "You can\u2019t undo this action." }), _jsx(NumberField, { source: "score" }), _jsx(UserCell, { source: "userId" }), _jsx(TextField, { source: "title" }), _jsx(ReferenceField, { label: "Task Name", reference: "Tasks", source: "taskId", children: _jsx(TextField, { source: "name" }) }), _jsx(DateField, { source: "finishDate", showTime: true }), _jsx(ReferenceArrayField, { reference: "Categories", source: "tags", children: _jsx(SingleFieldList, { children: _jsx(ChipField, { source: "name" }) }) })] }) }));
};
