import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { AutocompleteInput, BooleanInput, EditButton, List, NumberField, ReferenceField, ReferenceInput, TextField, TextInput } from "react-admin";
import { ListActions, exporter } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { ConditionalModifyButton } from "$components/List/ListActions/ConditionalModifyButton";
import { TasksDatagrid } from "./Tasks.datagrid";
import { ApplyTemplateButton } from "$components/List/ListActions/ApplyTemplateButton";
import { FilterSidebar, FilterSidebarTemplateCategory } from "$components/FilterSidebar";
export const Tasks = ({ isTemplate = false, ...props }) => {
    const references = {
        categoryKey: {
            appliedResource: "Categories",
            templateResource: "CategoriesTemplates"
        }
    };
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("Tasks"), filters: [
            _jsx(ReferenceInput, { name: "category", source: "categoryKey", label: "Category", reference: isTemplate ? "CategoriesTemplates" : "Categories", filter: { type: "TASK", isHidden: false }, allowEmpty: true, alwaysOn: true, children: _jsx(AutocompleteInput, { name: "categoryKey", label: "Category", optionText: "name", sx: { width: "250px" }, filterToQuery: (value) => ({ name: value }), variant: "filled", size: "small", fullWidth: true }) }),
            _jsx(TextInput, { name: "name", source: "name", placeholder: "Task name", sx: { width: "250px" }, variant: "filled", alwaysOn: true }),
            _jsx(BooleanInput, { name: "isHidden", source: "isHidden", label: "Show Hidden (Only)", size: "medium", options: {}, alwaysOn: true })
        ], filterDefaultValues: { isHidden: false }, ...props, aside: isTemplate ? (_jsx(FilterSidebar, { children: _jsx(FilterSidebarTemplateCategory, {}) })) : undefined, children: _jsxs(TasksDatagrid, { isTemplate: isTemplate, references: references, children: [_jsx(EditButton, { label: "", size: "medium" }), isTemplate && (_jsx(ApplyTemplateButton, { appliedResource: "Tasks", references: references })), isTemplate && _jsx(TextField, { source: "templateCategory" }), _jsx(ReferenceField, { label: "category", reference: isTemplate ? "CategoriesTemplates" : "Categories", source: "categoryKey", children: _jsx(TextField, { source: "name" }) }), _jsx(TextField, { source: "name" }), _jsx(NumberField, { source: "points" }), _jsx(ConditionalModifyButton, { source: "visibility", label: "Visibility", tooltipPlacement: "content", tooltip: (record) => (record["isHidden"] ? "Show" : "Hide"), icon: null, changedData: (record) => ({ isHidden: !record["isHidden"] }) })] }) }));
};
