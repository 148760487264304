import { styled } from "@mui/system";
export const TextBadgeContainer = styled("div")((props) => ({
    fontSize: "12px",
    color: "white",
    lineHeight: "15px",
    textTransform: "capitalize",
    width: "fit-content",
    minWidth: "80px",
    minHeight: "22px",
    padding: "2px 10px",
    borderRadius: "6px",
    backgroundColor: props.bgColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));
