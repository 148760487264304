import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Create, ReferenceInput, AutocompleteInput, SimpleForm, SaveButton, useNotify } from "react-admin";
import metadata from "$public/metadata.json";
import Toolbar from "@mui/material/Toolbar";
export const AdminsCreate = (createProps) => {
    const { __defaultValues = {} } = metadata.Admin;
    const notify = useNotify();
    return (_jsx(Create, { ...createProps, mutationOptions: {
            onError: (error) => {
                const errorText = typeof error === "string"
                    ? error
                    : error.message || "ra.notification.http_error";
                const idErrorRegex = /Error: the id:".*" already exists, please use a unique string if overriding the 'id' field/;
                notify(errorText.match(idErrorRegex)
                    ? "Selected User already has Admin access permissions."
                    : errorText, { type: "warning" });
            }
        }, children: _jsx(SimpleForm, { defaultValues: __defaultValues, toolbar: _jsx(Toolbar, { children: _jsx(SaveButton, { label: "Add Admin" }) }), children: _jsx(ReferenceInput, { label: "User", source: "id", reference: "Users", children: _jsx(AutocompleteInput, { label: "User", optionText: "username", filterToQuery: (q) => ({ username: q }), fullWidth: true, helperText: "Select User to Assign Admin Permissions" }) }) }) }));
};
