import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { List, Datagrid, DateField, FunctionField, ChipField, SingleFieldList, ReferenceManyField, EditButton } from "react-admin";
import { Pagination } from "$components/Pagination";
import { ListEmpty } from "$components/List/ListEmpty";
import { exporter, ListActions } from "$components/List/ListActions";
import { CopyInput } from "$components/Inputs/CopyInput";
const InvitedUsersList = () => (_jsx(ReferenceManyField, { reference: "Users", source: "id", target: "invitationId", children: _jsx(SingleFieldList, { children: _jsx(ChipField, { source: "email" }) }) }));
export const InviteLinks = () => {
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("InviteLinks"), sort: { field: "expirationDate", order: "DESC" }, children: _jsxs(Datagrid, { cellPadding: 0, expand: _jsx(InvitedUsersList, {}), expandSingle: true, children: [_jsx(EditButton, { label: "", size: "medium" }), _jsx(FunctionField, { source: "id", label: "Link", render: (record) => (_jsx(CopyInput, { value: `${process.env.CLIENT_APP_DOMAIN}${window.location.pathname}/invite?id=${record.id}` })) }), _jsx(DateField, { source: "expirationDate" })] }) }));
};
