import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Create, Edit, SimpleForm, Toolbar, SaveButton } from "react-admin";
import metadata from "$public/metadata.json";
import { ColorInput } from "$components/ColorPicker";
export const LevelColorsEdit = ({ variant, variantProps }) => {
    const FormWrapper = variant === "create" ? Create : Edit;
    const { __defaultValues = {} } = metadata.LevelColor;
    return (_jsx(FormWrapper, { ...variantProps, children: _jsxs(SimpleForm, { defaultValues: __defaultValues, toolbar: _jsx(Toolbar, { children: _jsx(SaveButton, {}) }), children: [_jsx(ColorInput, { name: "backgroundColor", source: "backgroundColor" }), _jsx(ColorInput, { name: "foregroundColor", source: "foregroundColor" })] }) }));
};
