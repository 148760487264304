import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { AutocompleteArrayInput, AutocompleteInput, Create, Edit, email, ImageField, NumberInput, ReferenceArrayInput, ReferenceInput, required, SaveButton, SimpleForm, TextInput, Toolbar } from "react-admin";
import metadata from "$public/metadata.json";
import { AdvancedImageInput } from "$components/Inputs/AdvancedImageInput";
import { ScalingStrategy } from "$components/Inputs/AdvancedImageInput/AdvancedImageInputUtils";
export const UsersEdit = ({ variant, variantProps }) => {
    const FormWrapper = variant === "create" ? Create : Edit;
    const { __defaultValues = {} } = metadata.User;
    return (_jsx(FormWrapper, { ...variantProps, children: _jsxs(SimpleForm, { defaultValues: __defaultValues, toolbar: _jsx(Toolbar, { children: _jsx(SaveButton, {}) }), children: [_jsx(TextInput, { name: "id", source: "id", fullWidth: true, disabled: variant === "edit" }), _jsx(TextInput, { source: "username", name: "username", fullWidth: true, validate: required() }), _jsx(TextInput, { source: "email", name: "email", fullWidth: true, validate: [required(), email()], disabled: variant === "edit" }), _jsx(NumberInput, { source: "level", name: "level", disabled: true, helperText: "If you want to modify user level, edit the number of points instead." }), _jsx(NumberInput, { source: "coins", name: "coins" }), _jsx(NumberInput, { source: "points", name: "points" }), _jsx(ReferenceArrayInput, { reference: "Badges", source: "badgesIds", children: _jsx(AutocompleteArrayInput, { label: "Owned badges", optionText: "title", filterToQuery: (q) => ({ title: q }), fullWidth: true, clearOnBlur: false }) }), _jsx(ReferenceInput, { reference: "Badges", source: "chosenBadgeId", children: _jsx(AutocompleteInput, { label: "Chosen Badge", optionText: "title", filterToQuery: (q) => ({ title: q }), fullWidth: true, clearOnBlur: false }) }), _jsx(AdvancedImageInput, { name: "profilePicture", source: "profilePicture", width: 140, height: 140, strategy: ScalingStrategy.MANUAL, children: _jsx(ImageField, { source: "src", title: "image" }) }), _jsx(ReferenceArrayInput, { reference: "Tasks", source: "favoriteTasksKeys", children: _jsx(AutocompleteArrayInput, { label: "Favourite Tasks", optionText: "name", filterToQuery: (q) => ({ name: q }), fullWidth: true, clearOnBlur: false }) })] }) }));
};
