import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BooleanInput, DateField, EditButton, FilterLiveSearch, List, NumberField, SaveButton, TextField } from "react-admin";
import { exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import { ConditionalModifyButton } from "$components/List/ListActions/ConditionalModifyButton";
import { LeaderboardsDatagrid } from "$components/Entities/Leaderboards/Leaderboards.datagrid";
const Leaderboards = (props) => {
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("Leaderboards"), filters: [
            _jsx(FilterLiveSearch, { source: "name", alwaysOn: true, placeholder: "Name", label: "Name" }),
            _jsx(BooleanInput, { placeholder: "Publicly Archived", source: "isHidden", label: "Show Archived (Only)", alwaysOn: true })
        ], filterDefaultValues: { isHidden: false }, sort: { field: "endDate", order: "DESC" }, toolbar: _jsx(Toolbar, { children: _jsx(SaveButton, {}) }), ...props, children: _jsxs(LeaderboardsDatagrid, { children: [_jsx(EditButton, { label: "", size: "medium" }), _jsx(TextField, { source: "name" }), _jsx(DateField, { source: "startDate" }), _jsx(DateField, { source: "endDate" }), _jsx(NumberField, { source: "totalPoints", label: "Total", emptyText: "N/A" }), _jsx(NumberField, { source: "taskPoints", label: "Tasks", emptyText: "N/A" }), _jsx(NumberField, { source: "challengePoints", label: "Challenges", emptyText: "N/A" }), _jsx(ConditionalModifyButton, { label: "Visibility", source: "visibility", tooltipPlacement: "content", tooltip: (record) => (record["isHidden"] ? "Unarchive" : "Archive"), icon: null, changedData: (record) => ({ isHidden: !record["isHidden"] }) })] }) }));
};
export { Leaderboards };
