import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useRecordContext, useRefresh, useResourceContext, useUpdate } from "react-admin";
import Checkbox from "@mui/material/Checkbox";
import { UncheckedIcon } from "./BooleanStateAction.styled";
export const BooleanStateAction = ({ source, disabled = false }) => {
    const refresh = useRefresh();
    const record = useRecordContext();
    const resource = useResourceContext();
    const [update, { isLoading }] = useUpdate(resource);
    const handleChange = (event, checked) => {
        event.preventDefault();
        return update(resource, {
            id: record.id,
            data: { ...record, [source]: checked },
            previousData: record
        }).then(() => refresh());
    };
    return (_jsx(Checkbox, { checked: Boolean(record[source]), onChange: handleChange, disabled: disabled || isLoading, indeterminate: isLoading, 
        // custom icon (with customized colour) to distinguish it from datagrid operation checkboxes
        icon: _jsx(UncheckedIcon, {}) }));
};
