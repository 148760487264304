import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BulkExportButton, BulkUpdateButton, Datagrid, useListContext } from "react-admin";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { BulkApplyTemplate } from "$components/List/ListBulkActions/BulkApplyTemplate";
export const TasksDatagrid = ({ children, isTemplate, references }) => {
    const { filterValues } = useListContext();
    return (_jsx(Datagrid, { cellPadding: 0, bulkActionButtons: _jsxs(_Fragment, { children: [filterValues["isHidden"] ? (_jsx(BulkUpdateButton, { label: "Activate", data: { isHidden: false }, icon: _jsx(VisibilityOffIcon, {}), mutationMode: "pessimistic" })) : (_jsx(BulkUpdateButton, { label: "Hide", data: { isHidden: true }, icon: _jsx(VisibilityIcon, {}), mutationMode: "pessimistic" })), _jsx(BulkExportButton, {}), isTemplate && (_jsx(BulkApplyTemplate, { appliedResource: "Tasks", references: references }))] }), children: children }));
};
