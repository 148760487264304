import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BulkExportButton, Datagrid, DateField, List, NumberField, ReferenceField, TextField } from "react-admin";
import { exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { UserCell } from "$components/TableCells/UserCell";
import React from "react";
export const IndividualRewards = (props) => {
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, { hasCreate: false }), exporter: exporter("IndividualRewards"), ...props, sort: { field: "earnedDate", order: "DESC" }, children: _jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsx(BulkExportButton, {}), children: [_jsx(ReferenceField, { reference: "Challenges", source: "challengeKey", label: "Challenge Name", children: _jsx(TextField, { source: "name" }) }), _jsx(UserCell, { source: "userId" }), _jsx(NumberField, { source: "earnedCoins" }), _jsx(ReferenceField, { reference: "Badges", source: "earnedBadge", emptyText: "N/A", children: _jsx(TextField, { source: "title" }) }), _jsx(DateField, { source: "earnedDate" })] }) }));
};
