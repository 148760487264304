import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LoginPage from "$components/LoginPage/LoginPage";
import { Admin, AppBar, Resource, Layout, ToggleThemeButton } from "react-admin";
import { ListCreate } from "$components/List/ListCreate";
import { ListEdit } from "$components/List/ListEdit";
import { LevelColors, LevelInfos, Users, UserTask, Tasks, Admins, AdminsCreate, Categories, Badges, IndividualRewards, Challenges, CoinLeaderboards, CoinLevels, CoinMonthlyChallenges, CoinGroupChallenges, Leaderboards, UserPoints, IndividualChallenges, UsersContribution, ChallengesContribution, AccountInvites, AppConfigsEdit, BadgesEdit, UserTasksEdit, AccountInvitesEdit, TasksEdit, ChallengesEdit, CategoriesEdit, GamespaceSettingsEdit, StorePrivateItems, StorePublicItems, StorePrivateItemsEdit, StorePublicItemsEdit, CoinLevelSettingsEdit, UsersEdit, UserPointsEdit, IndividualChallengesEdit, CoinLeaderboardsEdit, LeaderboardEdit, ChallengeContributionsEdit, LevelColorsEdit, ValueBits, ValueBitVotes, ValueBitsEdit, ChatFeedIntegrationsEdit, InviteLinks, InviteLinksEdit, GamespaceAppearanceEdit, ValueBitTags, ValueBitTagsEdit, Guests } from "$components/Entities";
import { NestedListEdit } from "$components/NestedList/NestedListEdit";
import { MyMenu } from "$components/Menu/Menu";
import { darkTheme, lightTheme } from "./theme";
import { StoreClaimedPrivateItems, StoreClaimedPrivateItemsIcon } from "$components/Entities/StoreClaimedPrivateItem";
import { StorePublicItemContribution } from "$components/Entities/StorePublicItemContribution";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import { ChatFeedIntegrations } from "$components/Entities/ChatFeedIntegrations/ChatFeedIntegrations";
import AssignmentIcon from "@mui/icons-material/Assignment";
import React from "react";
import ChallengeIcon from "@mui/icons-material/Flag";
import CategoryIcon from "@mui/icons-material/Category";
import BadgeIcon from "@mui/icons-material/Verified";
import UserTaskIcon from "@mui/icons-material/Badge";
import LeaderboardIcon from "@mui/icons-material/EmojiEvents";
import GroupChallengeIcon from "@mui/icons-material/FlagCircle";
import RewardIcon from "@mui/icons-material/MilitaryTech";
import CoinLeaderboardIcon from "@mui/icons-material/Star";
import LevelIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import ConfigIcon from "@mui/icons-material/ViewList";
import StoreIcon from "@mui/icons-material/StoreMallDirectory";
import ClaimedItemIcon from "@mui/icons-material/LocalGroceryStore";
import UserIcon from "@mui/icons-material/Person";
import AdminIcon from "@mui/icons-material/VerifiedUser";
import InvitationIcon from "@mui/icons-material/InsertInvitation";
import InvitationLinkIcon from "@mui/icons-material/InsertLink";
import LiveIntegrationIcon from "@mui/icons-material/LiveTv";
import ColorIcon from "@mui/icons-material/ColorLens";
import GuestIcon from "@mui/icons-material/PersonOutline";
import { Route } from "react-router-dom";
import { StorePublicItemsIcon } from "$components/Entities/StorePublicItem/StorePublicItems.icon";
import AppearanceIcon from "@mui/icons-material/ArtTrack";
import SettingsIcon from "@mui/icons-material/Settings";
const FEEDBACK_FORM_URL = "https://forms.clickup.com/2626936/f/2g5br-47888/FWIJ1D9D74BLV8CUME";
/** Component required due to firebase init x craco bundling behaviour - this suck*/
const AdminResourcesList = ({ authProvider, dataProvider }) => (_jsx(Admin, { theme: darkTheme, loginPage: LoginPage, authProvider: authProvider, dataProvider: dataProvider, layout: (props) => (_jsx(Layout, { ...props, menu: MyMenu, appBar: (props) => (_jsxs(AppBar, { ...props, children: [_jsx(Box, { flex: "1", children: _jsx(Typography, { variant: "h6", id: "react-admin-title" }) }), _jsx(Link, { href: FEEDBACK_FORM_URL, target: "_blank", color: "inherit", p: "4px 16px", style: { textDecoration: "none" }, children: _jsx(Typography, { children: "Leave feedback" }) }), _jsx(ToggleThemeButton, { lightTheme: lightTheme, darkTheme: darkTheme })] })) })), children: (auth) => {
        const isSuperUser = auth?.roles?.includes("superuser") || null;
        return [
            _jsx(Resource, { name: "Admins", options: { label: "Admin Permissions" }, create: AdminsCreate, list: Admins, icon: AdminIcon }),
            _jsx(Resource, { name: "AccountInvites", options: { label: "Game Invitations" }, create: (props) => _jsx(AccountInvitesEdit, { variantProps: props, variant: "create" }), edit: (props) => _jsx(AccountInvitesEdit, { variantProps: props, variant: "edit" }), list: AccountInvites, icon: InvitationIcon }),
            isSuperUser ? (_jsx(Resource, { name: "AppConfigs", create: (props) => _jsx(AppConfigsEdit, { variantProps: props, variant: "create" }), edit: (props) => _jsx(AppConfigsEdit, { variantProps: props, variant: "edit" }), options: { label: "Meta App Configuration" }, icon: ConfigIcon })) : null,
            _jsx(Resource, { name: "ChatFeedIntegrations", list: ChatFeedIntegrations, create: (props) => (_jsx(ChatFeedIntegrationsEdit, { variantProps: props, variant: "create" })), edit: (props) => (_jsx(ChatFeedIntegrationsEdit, { variantProps: props, variant: "edit" })), options: { label: "Live Feed Integrations" }, icon: LiveIntegrationIcon }),
            _jsx(Resource, { name: "GamespaceSettings", create: (props) => (_jsx(GamespaceSettingsEdit, { variantProps: props, variant: "create" })), edit: (props) => _jsx(GamespaceSettingsEdit, { variantProps: props, variant: "edit" }), options: { label: "Gamespace Settings" }, icon: SettingsIcon }),
            _jsx(Resource, { name: "GamespaceAppearance", create: (props) => (_jsx(GamespaceAppearanceEdit, { variantProps: props, variant: "create" })), edit: (props) => (_jsx(GamespaceAppearanceEdit, { variantProps: props, variant: "edit" })), options: { label: "Space Appearance" }, icon: AppearanceIcon }),
            _jsx(Resource, { name: "Badges", create: (props) => _jsx(BadgesEdit, { variantProps: props, variant: "create" }), edit: (props) => _jsx(BadgesEdit, { variantProps: props, variant: "edit" }), list: Badges, icon: BadgeIcon }),
            _jsx(Resource, { name: "BadgesTemplates", create: (props) => isSuperUser && (_jsx(BadgesEdit, { variantProps: props, variant: "create", isTemplate: true })), edit: (props) => isSuperUser && _jsx(BadgesEdit, { variantProps: props, variant: "edit", isTemplate: true }), list: ({ children, ...props }) => (_jsx(Badges, { ...props, isTemplate: true, children: children })), icon: BadgeIcon, options: { label: "Badges" } }),
            _jsxs(Resource, { name: "Categories", create: (props) => _jsx(CategoriesEdit, { variantProps: props, variant: "create" }), edit: (props) => _jsx(CategoriesEdit, { variantProps: props, variant: "edit" }), list: Categories, icon: CategoryIcon, children: [_jsx(Route, { path: "ValueBitTags", element: _jsx(ValueBitTags, {}) }), _jsx(Route, { path: "ValueBitTags/create", element: _jsx(ValueBitTagsEdit, { variantProps: {}, variant: "create" }) }), _jsx(Route, { path: "ValueBitTags/:id", element: _jsx(ValueBitTagsEdit, { variantProps: {}, variant: "edit" }) })] }),
            _jsx(Resource, { name: "CategoriesTemplates", create: (props) => isSuperUser && (_jsx(CategoriesEdit, { variantProps: props, variant: "create", isTemplate: true })), edit: (props) => isSuperUser && (_jsx(CategoriesEdit, { variantProps: props, variant: "edit", isTemplate: true })), list: ({ children, ...props }) => (_jsx(Categories, { ...props, isTemplate: true, children: children })), icon: CategoryIcon, options: { label: "Categories" } }),
            _jsx(Resource, { name: "Challenges", create: (props) => _jsx(ChallengesEdit, { variantProps: props, variant: "create" }), edit: (props) => _jsx(ChallengesEdit, { variantProps: props, variant: "edit" }), list: Challenges, icon: ChallengeIcon }),
            _jsx(Resource, { name: "ChallengesTemplates", create: (props) => isSuperUser && (_jsx(ChallengesEdit, { variantProps: props, variant: "create", isTemplate: true })), edit: (props) => isSuperUser && (_jsx(ChallengesEdit, { variantProps: props, variant: "edit", isTemplate: true })), list: ({ children, ...props }) => (_jsx(Challenges, { ...props, isTemplate: true, children: children })), icon: ChallengeIcon, options: { label: "Challenges" } }),
            _jsx(Resource, { name: "Guests", options: { label: "Guests" }, list: Guests, icon: GuestIcon }),
            _jsx(Resource, { name: "UserChallengeContribution", options: { label: "Group Challenges' Contributors" }, edit: ChallengeContributionsEdit, list: ChallengesContribution, icon: GroupChallengeIcon }),
            _jsx(Resource, { name: "StoreClaimedPrivateItems", options: { label: "Individual Store Items Claimed" }, create: ListCreate, list: StoreClaimedPrivateItems, icon: StoreClaimedPrivateItemsIcon }),
            _jsx(Resource, { name: "StorePrivateItems", options: { label: "Individual Store Item Listing" }, edit: (props) => _jsx(StorePrivateItemsEdit, { variantProps: props, variant: "edit" }), create: (props) => (_jsx(StorePrivateItemsEdit, { variantProps: props, variant: "create" })), list: StorePrivateItems, icon: StoreIcon }),
            _jsx(Resource, { name: "StorePublicItems", options: { label: "Group Store Item Listing" }, edit: (props) => _jsx(StorePublicItemsEdit, { variantProps: props, variant: "edit" }), create: (props) => (_jsx(StorePublicItemsEdit, { variantProps: props, variant: "create" })), list: StorePublicItems, icon: StorePublicItemsIcon }),
            _jsx(Resource, { name: "StorePublicItemContributions", options: { label: "Group Store Item Contributors" }, edit: ListEdit, create: ListCreate, list: StorePublicItemContribution, icon: ClaimedItemIcon }),
            _jsx(Resource, { name: "RewardUserContribution", options: { label: "GroupRewardsUsersContributions" }, edit: NestedListEdit, list: UsersContribution }),
            _jsx(Resource, { name: "Leaderboards", options: { label: "Create / Modify Leaderboards" }, edit: (props) => _jsx(LeaderboardEdit, { variantProps: props, variant: "edit" }), create: (props) => _jsx(LeaderboardEdit, { variantProps: props, variant: "create" }), list: Leaderboards, icon: LeaderboardIcon }),
            _jsx(Resource, { name: "CoinLeaderboards", options: { label: "Monthly Overview" }, edit: (props) => _jsx(CoinLeaderboardsEdit, { variantProps: props, variant: "edit" }), create: (props) => (_jsx(CoinLeaderboardsEdit, { variantProps: props, variant: "create" })), list: CoinLeaderboards, icon: CoinLeaderboardIcon }),
            _jsx(Resource, { name: "CoinLevels", options: { label: "Levels" }, list: CoinLevels, icon: LevelIcon }),
            _jsx(Resource, { name: "CoinMonthlyChallenges", options: { label: "Individual Challenges" }, list: CoinMonthlyChallenges, icon: ChallengeIcon }),
            _jsx(Resource, { name: "CoinGroupChallenges", options: { label: "Group Challenges" }, list: CoinGroupChallenges, icon: GroupChallengeIcon }),
            _jsx(Resource, { name: "LevelColors", options: { label: "Leveling Up Colors Data" }, edit: (props) => _jsx(LevelColorsEdit, { variantProps: props, variant: "edit" }), create: (props) => _jsx(LevelColorsEdit, { variantProps: props, variant: "create" }), list: LevelColors, icon: ColorIcon }),
            _jsx(Resource, { name: "LevelInfos", options: { label: "Leveling Up Data" }, create: ListCreate, edit: ListEdit, list: LevelInfos, icon: LevelIcon }),
            _jsx(Resource, { name: "CoinLevelSettings", options: { label: "Coin/Lvl settings" }, edit: (props) => _jsx(CoinLevelSettingsEdit, { variantProps: props, variant: "edit" }), create: (props) => (_jsx(CoinLevelSettingsEdit, { variantProps: props, variant: "create" })), icon: ConfigIcon }),
            _jsx(Resource, { name: "UsersPoints", options: { label: "Leaderboard Contributors" }, edit: UserPointsEdit, list: UserPoints, icon: LeaderboardIcon }),
            _jsx(Resource, { name: "Tasks", edit: (props) => _jsx(TasksEdit, { variantProps: props, variant: "edit" }), create: (props) => _jsx(TasksEdit, { variantProps: props, variant: "create" }), list: Tasks, icon: AssignmentIcon }),
            _jsx(Resource, { name: "TasksTemplates", edit: (props) => isSuperUser && _jsx(TasksEdit, { variantProps: props, variant: "edit", isTemplate: true }), create: (props) => isSuperUser && (_jsx(TasksEdit, { variantProps: props, variant: "create", isTemplate: true })), list: ({ children, ...props }) => (_jsx(Tasks, { ...props, isTemplate: true, children: children })), icon: AssignmentIcon, options: { label: "Tasks" } }),
            _jsx(Resource, { name: "UserTasks", options: { label: "Submitted Tasks" }, edit: UserTasksEdit, list: UserTask, icon: UserTaskIcon }),
            _jsx(Resource, { name: "Users", options: { label: "User Info" }, edit: (props) => _jsx(UsersEdit, { variantProps: props, variant: "edit" }), create: (props) => _jsx(UsersEdit, { variantProps: props, variant: "create" }), list: Users, icon: UserIcon }),
            _jsx(Resource, { name: "UserIndividualChallenges", options: { label: "Individual Challenges' Player Progress" }, edit: IndividualChallengesEdit, list: IndividualChallenges, icon: ChallengeIcon }),
            _jsx(Resource, { name: "IndividualRewards", options: { label: "Reward log" }, list: IndividualRewards, icon: RewardIcon }),
            _jsx(Resource, { name: "InviteLinks", options: { label: "Invite Links" }, list: InviteLinks, edit: (props) => _jsx(InviteLinksEdit, { variantProps: props, variant: "edit" }), create: (props) => _jsx(InviteLinksEdit, { variantProps: props, variant: "create" }), icon: InvitationLinkIcon }),
            _jsx(Resource, { name: "ValueBits", options: { label: "Value Bits" }, edit: (props) => _jsx(ValueBitsEdit, { variantProps: props, variant: "edit" }), create: (props) => _jsx(ValueBitsEdit, { variantProps: props, variant: "create" }), list: ValueBits, icon: UserTaskIcon }),
            _jsx(Resource, { name: "ValueBitVotes", options: { label: "Interactions log" }, edit: ListEdit, create: ListCreate, list: ValueBitVotes, icon: LeaderboardIcon })
        ];
    } }));
export { AdminResourcesList };
