import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import "reflect-metadata";
import { FirebaseAuthProvider, FirebaseDataProvider } from "react-admin-firebase";
import { initializeFirebaseService } from "./firebaseAdminInit";
import { AdminResourcesList } from "./AdminResourcesList";
const App = () => {
    const LazyAdminResources = React.lazy(async () => {
        const { config, adminOptions } = await initializeFirebaseService();
        const dataProvider = FirebaseDataProvider(config, adminOptions);
        // TODO: soft delete customization for dataProvider
        // const softDeletedField = "archived";
        //
        // dataProvider.delete = (resource, params) =>
        //     dataProvider.update(resource, {
        //         ...(params as UpdateParams),
        //         data: {[softDeletedField]: true}
        //     });
        //
        // dataProvider.deleteMany = (resource, params) =>
        //     dataProvider.updateMany(resource, {...params, data: {[softDeletedField]: true}});
        //
        // const getList = dataProvider.getList;
        // dataProvider.getList = (resource, params) =>
        //     getList(resource, {...params}).then((result) => ({
        //         ...result,
        //         data: params.filter?.archived
        //             ? result.data
        //             : result.data.filter((a) => !a[softDeletedField])
        //     }));
        return {
            default: () => (_jsx(AdminResourcesList, { authProvider: FirebaseAuthProvider(config, adminOptions), dataProvider: dataProvider }))
        };
    });
    return (_jsx(React.StrictMode, { children: _jsx(React.Suspense, { fallback: _jsx("h2", { children: "Loading..." }), children: _jsx(LazyAdminResources, {}) }) }));
};
export default App;
const root = createRoot(document.getElementById("root"));
root.render(_jsx(App, {}));
