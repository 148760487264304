import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { required, SelectInput } from "react-admin";
import { challengeTypeChoices } from "$constants/challengeTypeChoices";
export const TypeInput = () => {
    const { watch, resetField } = useFormContext();
    const inputValue = watch("type");
    // Resetting the requiredCompletion field when challenge type changes,
    // since this field is only available for individual challenges
    useEffect(() => {
        resetField("requiresCompletion");
    }, [inputValue]);
    return (_jsx(SelectInput, { name: "type", placeholder: "type", source: "type", choices: challengeTypeChoices, validate: required() }));
};
