import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { isMetaPrimitive } from "$utils/metadata.utils";
import { DateField, NumberField, RichTextField, TextField } from "react-admin";
import { ColorField } from "$components/ColorPicker";
import { UserCell } from "$components/TableCells/UserCell";
import { CustomReferenceCell } from "$components/TableCells/CustomReferenceCell";
export const CellComponent = ({ keyName, value, ...props }) => {
    if (isMetaPrimitive(value)) {
        if (value.type === "Number") {
            return _jsx(NumberField, { ...props });
        }
        if (value.type === "Date") {
            return _jsx(DateField, { ...props });
        }
        if (value.type === "richText") {
            return _jsx(RichTextField, { ...props });
        }
        if (keyName === "color" || keyName === "backgroundColor" || keyName === "foregroundColor") {
            return _jsx(ColorField, { ...props });
        }
        return _jsx(TextField, { ...props });
    }
    if (value.modelName === "User") {
        return _jsx(UserCell, { ...props });
    }
    if (value.modelName === "Task") {
        return (_jsx(CustomReferenceCell, { ...props, reference: "Tasks", source: "name", referenceSource: props.source, isArray: value.isArray }));
    }
    return _jsx(TextField, { ...props });
};
