import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { Button } from "react-admin";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
export const CopyInput = ({ value }) => {
    const inputRef = React.createRef();
    useEffect(() => {
        if (!inputRef)
            return;
        inputRef.current.onclick = () => {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(0, inputRef.current.value.length);
        };
    }, [inputRef]);
    return (_jsx(Input, { value: value, inputRef: inputRef, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(Button, { variant: "contained", onClick: () => navigator.clipboard.writeText(value), children: _jsx(_Fragment, { children: "Copy" }) }) }), fullWidth: true }));
};
