import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BooleanField, BooleanInput, Datagrid, DateField, List, SearchInput, TextField } from "react-admin";
import { DeleteItemButton, exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { ListBulkActions } from "$components/List/ListBulkActions";
import { ConditionalEditButton } from "$components/List/ListActions/ConditionalEditButton";
const AccountInvites = (props) => {
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("AccountInvites"), filterDefaultValues: { used: false }, filters: [
            _jsx(SearchInput, { placeholder: "User Email", source: "email", alwaysOn: true }),
            _jsx(BooleanInput, { placeholder: "Used", source: "used", label: "Show Used (Only)", alwaysOn: true })
        ], ...props, children: _jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsx(ListBulkActions, {}), children: [_jsx(ConditionalEditButton, { isDisabled: (record) => Boolean(record["used"]), size: "small", label: "" }), _jsx(DeleteItemButton, { customConfirmContent: "" }), _jsx(TextField, { source: "email" }), _jsx(DateField, { source: "expirationDate" }), _jsx(BooleanField, { source: "used" })] }) }));
};
export { AccountInvites };
