import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Create, Edit, SaveButton, SimpleForm, Toolbar } from "react-admin";
import { getInputComponent } from "$utils/getInputComponent";
import addDays from "date-fns/addDays";
import { CommaSeparatedList } from "$components/Inputs/CommaSeparatedList";
export const InviteLinksEdit = ({ variant, variantProps }) => {
    const FormWrapper = variant === "create" ? Create : Edit;
    const ExpirationDateInput = getInputComponent("date", "expirationDate");
    return (_jsx(FormWrapper, { ...variantProps, redirect: "list", children: _jsxs(SimpleForm, { toolbar: _jsx(Toolbar, { children: _jsx(SaveButton, { alwaysEnable /*allow submit with just default value*/: true }) }), children: [_jsx(ExpirationDateInput, { source: "expirationDate", defaultValue: addDays(new Date(), 7), helperText: "One week long by default.", fullWidth: true }), _jsx(CommaSeparatedList, { source: "whitelistedDomains", helperText: "For example 'gmail.com'" })] }) }));
};
