import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { FilterList, FilterListItem, useInfiniteGetList } from "react-admin";
export const CategoryFilter = ({ type, source, isTemplate }) => {
    const { data } = useInfiniteGetList(isTemplate ? "CategoriesTemplates" : "Categories", {
        filter: { type, isHidden: false },
        sort: { field: "priority", order: "asc" }
    });
    return (_jsx(FilterList, { label: "Category", icon: "", children: data?.pages
            .flatMap((page) => page.data)
            .map((choice) => (_jsx(FilterListItem, { label: choice.name, value: { [source]: choice.id } }, choice.id))) }));
};
