import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ReferenceField, TextField, useRecordContext } from "react-admin";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
export const AvatarField = ({ source }) => {
    const record = useRecordContext();
    // support for storing image urls as both string and bucket object references (strings deprecated)
    const imageSource = typeof record[source] === "string" ? record[source] : record[source]?.src;
    return _jsx(Avatar, { src: imageSource });
};
AvatarField.defaultProps = { sortable: false };
export const UserCell = ({ source }) => {
    return (_jsx(ReferenceField, { source: source, reference: "Users", children: _jsxs(Box, { sx: { display: "flex", alignItems: "center", justifyContent: "flex-start" }, children: [_jsx(AvatarField, { source: "profilePicture" }), _jsx(TextField, { source: "username", sx: { marginLeft: "10px" } })] }) }));
};
