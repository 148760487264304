import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AutocompleteInput, ReferenceInput, ReferenceArrayInput, AutocompleteArrayInput } from "react-admin";
import { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
const modelsThatCanBeHidden = ["Task", "Reward", ""];
const AutocompleteCell = ({ multichoice, modelName, referenceId, referenceField, choices, collectionReference = "", source, ...restProps }) => {
    const [showHiddenRefs, setShowHiddenRefs] = useState(false);
    // TODO: Extract to another component?
    if (choices) {
        return renderSpecificInput({ choices, source });
    }
    function renderSpecificInput(extraProps = {}) {
        // TODO: add suggestion limits for large enough datasets to improve performance
        //  will need to somehow include the selected value on the list of suggested values as well even if it's not matching
        if (multichoice) {
            return (_jsx(AutocompleteArrayInput, { filterToQuery: (q) => ({ ...getFilter(), [referenceField]: q }), ...extraProps, clearOnBlur: false, optionText: referenceField, fullWidth: true }));
        }
        return (_jsx(AutocompleteInput, { filterToQuery: (currentValue) => ({ ...getFilter(), [referenceField]: currentValue }), source: "yo", ...extraProps, optionText: referenceField, fullWidth: true }));
    }
    function getFilter() {
        if (refFieldHasIsHidden && typeof showHiddenRefs !== "undefined") {
            return { isHidden: showHiddenRefs };
        }
        return {};
    }
    const ReferenceWrapperComponent = multichoice ? ReferenceArrayInput : ReferenceInput;
    const refFieldHasIsHidden = modelsThatCanBeHidden.includes(modelName);
    return (_jsxs(_Fragment, { children: [_jsx(ReferenceWrapperComponent, { label: `${source} (${modelName})`, source: source, reference: collectionReference, alwaysOn: true, filter: getFilter(), children: renderSpecificInput() }), refFieldHasIsHidden && (_jsx(FormGroup, { children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: showHiddenRefs, onChange: (evt) => setShowHiddenRefs(Boolean(evt.target.checked)), size: "small" }), label: `Show ${collectionReference} which are archived. `, style: { fontSize: "x-small" } }) }))] }));
};
export { AutocompleteCell };
