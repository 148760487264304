import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Create, Edit, SaveButton, SimpleForm, Toolbar } from "react-admin";
import metadata from "$public/metadata.json";
import { CategoriesFormFields } from "$components/Entities/Categories/CategoriesFormFields";
export const ValueBitTagsEdit = ({ variant, variantProps }) => {
    const FormWrapper = variant === "create" ? Create : Edit;
    const { __defaultValues = {} } = metadata.Category;
    return (_jsx(FormWrapper, { ...variantProps, redirect: "/Categories/ValueBitTags", children: _jsx(SimpleForm, { defaultValues: __defaultValues, toolbar: _jsx(Toolbar, { children: _jsx(SaveButton, {}) }), children: _jsx(CategoriesFormFields, { predefinedType: "VALUE_BIT_TAG", isTemplate: false }) }) }));
};
