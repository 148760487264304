import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { getInputComponent } from "$utils/getInputComponent";
import { useFormContext } from "react-hook-form";
import { addDays } from "date-fns";
export const ExpirationDateInput = (dateInputProps) => {
    const Input = getInputComponent("date", "expirationDate");
    const form = useFormContext();
    return (_jsx(Input, { source: "expirationDate", name: "expirationDate", defaultValue: addDays(new Date(), 7), required: true, helperText: form.formState.dirtyFields.expirationDate ? undefined : "One week by default", ...dateInputProps }));
};
