import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useGetOne } from "react-admin";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
export const UnsubscribePaymentButton = ({ leftIcon, primaryText }) => {
    const { data: appConfig, error, isLoading } = useGetOne("AppConfigs", { id: "COINFIG" }, { retry: false });
    const isDisabled = isLoading || Boolean(error) || !appConfig?.unsubscribePaymentLink;
    return (_jsx(Link, { href: appConfig?.unsubscribePaymentLink || void 0, target: "_blank", color: "inherit", style: { textDecoration: "none" }, children: _jsxs(MenuItem, { disabled: isDisabled, children: [_jsx(ListItemIcon, { style: { minWidth: "40px" }, children: leftIcon }), _jsx(Typography, { variant: "inherit", color: "textSecondary", children: primaryText })] }) }));
};
