import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useRef, useState } from "react";
import { Button, ImageInput } from "react-admin";
import { useInput } from "ra-core";
import { scaleImageBeforeUpload, ScalingStrategy, transformFiles } from "./AdvancedImageInputUtils";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconCancel from "@mui/icons-material/Cancel";
import IconSave from "@mui/icons-material/Save";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
export const AdvancedImageInput = (props) => {
    const { multiple, format, parse, validate, source, width, height, options, children, strategy = ScalingStrategy.CROP, ...rest } = props;
    const { field: { onChange, value: files } } = useInput({
        format: format || transformFiles.bind(this, multiple),
        parse: parse || transformFiles.bind(this, multiple),
        source,
        validate,
        ...rest
    });
    const cropperRef = useRef(null);
    const [filesHistory, setFilesHistory] = useState(files);
    const [cropDialogVisibility, setCropDialogVisibility] = useState(false);
    const handleCloseClick = () => {
        onChange(filesHistory);
        setCropDialogVisibility(false);
    };
    const cropMultipleImages = (newFiles) => Promise.all(newFiles.map((newFile) => scaleImageBeforeUpload(newFile, { width, height }, strategy))).then((scaledNewFiles) => onChange([...files, ...scaledNewFiles]));
    const cropSingleImage = () => {
        setCropDialogVisibility(false);
        return new Promise((res) => cropperRef?.current?.cropper.getCroppedCanvas().toBlob(res))
            .then((blob) => scaleImageBeforeUpload(new File([blob], files?.name, { type: "image/png" }), { width, height }, ScalingStrategy.MANUAL))
            .then(onChange);
    };
    return (_jsxs(_Fragment, { children: [!multiple && strategy === ScalingStrategy.MANUAL && (_jsxs(Dialog, { open: cropDialogVisibility, children: [_jsx(DialogTitle, { children: "Scale Image" }), _jsx(DialogContent, { children: _jsx(Cropper, { aspectRatio: width / height, guides: true, src: files?.src, ref: cropperRef, viewMode: 1, dragMode: "move", background: true, minCropBoxHeight: width, minCropBoxWidth: height }) }), _jsxs(DialogActions, { children: [_jsx(Button, { label: "ra.action.cancel", onClick: handleCloseClick, children: _jsx(IconCancel, {}) }), _jsx(Button, { label: "ra.action.save", onClick: cropSingleImage, children: _jsx(IconSave, {}) })] })] })), _jsx(ImageInput, { ...props, options: {
                    ...options,
                    onDrop: async (newFiles, rejectedFiles, event) => {
                        setFilesHistory(files);
                        if (!multiple && strategy === ScalingStrategy.MANUAL) {
                            setCropDialogVisibility(true);
                        }
                        else
                            await cropMultipleImages(newFiles);
                        options?.onDrop?.(newFiles, rejectedFiles, event);
                    }
                }, sx: { ...props.sx, height: "auto" }, children: children })] }));
};
