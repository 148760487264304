import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { ArrayInput, BooleanInput, Create, Edit, NumberInput, required, SaveButton, SimpleForm, SimpleFormIterator, TextInput, Toolbar } from "react-admin";
import metadata from "$public/metadata.json";
import { getInputComponent } from "$utils/getInputComponent";
export const LeaderboardEdit = ({ variant, variantProps }) => {
    const FormWrapper = variant === "create" ? Create : Edit;
    const { __defaultValues = {} } = metadata.Leaderboard;
    const StartDateInput = getInputComponent("date", "startDate");
    const EndDateInput = getInputComponent("date", "endDate");
    return (_jsx(FormWrapper, { ...variantProps, children: _jsxs(SimpleForm, { defaultValues: __defaultValues, toolbar: _jsx(Toolbar, { children: _jsx(SaveButton, {}) }), children: [_jsx(TextInput, { name: "name", source: "name", validate: required(), fullWidth: true }), _jsx(StartDateInput, { source: "startDate", name: "startDate" }), _jsx(EndDateInput, { source: "endDate", name: "endDate" }), _jsx(BooleanInput, { source: "isHidden", name: "isHidden", label: "Archived", options: {} }), _jsx(NumberInput, { source: "totalPoints", name: "totalPoints", disabled: true }), _jsx(NumberInput, { source: "taskPoints", name: "taskPoints", disabled: true }), _jsx(NumberInput, { source: "challengePoints", name: "challengePoints", disabled: true }), _jsx(ArrayInput, { source: "diamondsConfig", children: _jsx(SimpleFormIterator, { inline: true, getItemLabel: (index) => `#${index + 1}`, children: _jsx(NumberInput, { source: "amount", helperText: false, defaultValue: 0, step: 0, min: 0 }) }) })] }) }));
};
