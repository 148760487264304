import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BulkExportButton, Datagrid, NumberField } from "react-admin";
import { UserCell } from "$components/TableCells/UserCell";
export const CoinLevelsListData = () => {
    const actionButtonStyle = { padding: 0, minWidth: 32, maxWidth: 32, flexGrow: 0 };
    return (_jsxs(Datagrid, { cellPadding: 0, sx: {
            "& .column-undefined": actionButtonStyle /* action buttons styling is also applied to their columns*/
        }, bulkActionButtons: _jsx(BulkExportButton, {}), children: [_jsx(UserCell, { source: "userId" }), _jsx(NumberField, { source: "coins" })] }));
};
