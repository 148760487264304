import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BooleanInput, EditButton, FilterLiveSearch, List, NumberField, TextField } from "react-admin";
import { exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { ConditionalModifyButton } from "$components/List/ListActions/ConditionalModifyButton";
import { StorePrivateItemsDatagrid } from "$components/Entities/StorePrivateItem/StorePrivateItems.datagrid";
export const StorePrivateItems = (props) => {
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("StorePrivateItems"), filters: [
            _jsx(FilterLiveSearch, { source: "title", alwaysOn: true, placeholder: "Title" }),
            _jsx(BooleanInput, { source: "archived", label: "Show Archived (Only)", alwaysOn: true })
        ], filterDefaultValues: { archived: false }, ...props, children: _jsxs(StorePrivateItemsDatagrid, { children: [_jsx(EditButton, { label: "", size: "medium" }), _jsx(TextField, { source: "title" }), _jsx(NumberField, { source: "coinCost" }), _jsx(ConditionalModifyButton, { source: "visibility", label: "Visibility", tooltipPlacement: "content", tooltip: (record) => (record["archived"] ? "Unarchive" : "Archive"), icon: null, changedData: (record) => ({ archived: !record["archived"] }) })] }) }));
};
