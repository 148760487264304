import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Create, Edit, SimpleForm, TextInput, BooleanInput, required, email } from "react-admin";
import metadata from "$public/metadata.json";
import { ExpirationDateInput } from "./Inputs";
import { HiddenInput } from "$components/Inputs";
export const AccountInvitesEdit = ({ variant, variantProps }) => {
    const FormWrapper = variant === "create" ? Create : Edit;
    const { __defaultValues = {} } = metadata.AccountInvite;
    return (_jsx(FormWrapper, { ...variantProps, redirect: "list", children: _jsxs(SimpleForm, { defaultValues: __defaultValues, children: [_jsx(HiddenInput, { source: "mailingDomain", value: process.env.CLIENT_APP_DOMAIN }), _jsx(TextInput, { source: "email", name: "email", fullWidth: true, validate: [required(), email()], helperText: "The invitation message might end up in spam folder, ensure the invitation receiver checks it or send them a group invite link." }), _jsx(BooleanInput, { source: "makeAdmin", name: "makeAdmin", options: {} }), _jsx(ExpirationDateInput, {})] }) }));
};
