import { addMinutes, subMinutes } from "date-fns";
/**
 * Function formatting the date from local timezone to UTC
 * @param {Date} date Date to be formatted
 * @returns {Date} Date formatted to UTC timezone
 */
function toUTC(date) {
    return addMinutes(date, date.getTimezoneOffset());
}
/**
 * Function formatting the date from UTC to local timezone
 * @param {Date} date Date to be formatted
 * @returns {Date} Date formatted to local timezone
 */
function fromUTC(date) {
    return subMinutes(date, date.getTimezoneOffset());
}
export { toUTC, fromUTC };
