const transformFile = (file) => {
    return file instanceof File
        ? {
            rawFile: file,
            src: URL.createObjectURL(file),
            title: file.name
        }
        : file;
};
export const transformFiles = (multiple, files) => {
    if (!files) {
        return multiple ? [] : null;
    }
    if (Array.isArray(files)) {
        return files.map(transformFile);
    }
    return transformFile(files);
};
