import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { useCreatePath, useResourceContext, useResourceDefinition, useTranslate } from "ra-core";
import Inbox from "@mui/icons-material/Inbox";
import Typography from "@mui/material/Typography";
import { EmptyClasses } from "ra-ui-materialui";
import { ImportData } from "$components/List/ListActions";
import { ListEmptyContainer } from "$components/List/ListEmpty/ListEmpty.styled";
import { Link } from "react-router-dom";
import ContentAdd from "@mui/icons-material/Add";
import { Button } from "react-admin";
export const ValueBitTagsEmpty = (props) => {
    const { className } = props;
    const { hasCreate } = useResourceDefinition(props);
    const resource = useResourceContext(props);
    const createPath = useCreatePath();
    const translate = useTranslate();
    const resourceName = "Value Bit Tag";
    const emptyMessage = translate("ra.page.empty", { name: resourceName });
    const inviteMessage = translate("ra.page.invite");
    return (_jsxs(ListEmptyContainer, { className: className, children: [_jsxs("div", { className: EmptyClasses.message, children: [_jsx(Inbox, { className: EmptyClasses.icon }), _jsx(Typography, { variant: "h4", paragraph: true, children: translate(`resources.valueBitTag.empty`, {
                            _: emptyMessage
                        }) }), hasCreate && (_jsx(Typography, { variant: "body1", children: translate(`resources.valueBitTag.invite`, {
                            _: inviteMessage
                        }) }))] }), hasCreate && (_jsxs("div", { className: EmptyClasses.toolbar, children: [_jsx(ImportData, { ...props, variant: "contained" }), _jsx(Button, { color: "primary", component: Link, variant: "contained", label: "ra.action.create", to: {
                            pathname: createPath({
                                resource: `${resource}/ValueBitTags`,
                                type: "create"
                            }),
                            search: `?source=${JSON.stringify({ type: "VALUE_BIT_TAG" })}`
                        }, children: _jsx(ContentAdd, {}) })] }))] }));
};
