import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from "react";
import { useDataProvider, useListContext, useNotify } from "ra-core";
import { Button } from "ra-ui-materialui";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { Confirm, useCreate, useUnselectAll, useUpdate } from "react-admin";
import pLimit from "p-limit";
import { applyReferencedResources } from "$components/List/ListActions/ApplyTemplateButton/ApplyTemplateButton.utils";
export const BulkApplyTemplate = ({ appliedResource, references = {} }) => {
    const { resource, selectedIds } = useListContext();
    const dataProvider = useDataProvider();
    const [applyConfirmOpen, setApplyConfirmOpen] = useState(false);
    const [collidingIds, setCollidingIds] = useState([]);
    const notify = useNotify();
    const [create, { isLoading: isCreateLoading }] = useCreate();
    const [update, { isLoading: isUpdateLoading }] = useUpdate();
    const unselectAll = useUnselectAll(resource);
    const limit = pLimit(5);
    const handleClick = useCallback((event) => {
        event.preventDefault();
        setApplyConfirmOpen(true);
    }, []);
    const applyTemplates = useCallback(() => {
        return dataProvider
            .getMany(resource, { ids: selectedIds })
            .then(({ data: selectedTemplatesData }) => {
            const records = selectedTemplatesData.reduce((all, a) => {
                collidingIds.includes(a.id) ? all.toUpdate.push(a) : all.toCreate.push(a);
                return all;
            }, { toUpdate: [], toCreate: [] });
            // fetchRelatedRecords(dataProvider);
            return Promise.all([
                ...records.toUpdate.map((record) => limit(() => update(appliedResource, { id: record.id, data: record }, { returnPromise: true }))),
                ...records.toCreate.map((record) => limit(() => create(appliedResource, { data: record }, { returnPromise: true })))
            ]);
        })
            .then(() => createMissingReferencedResources())
            .catch((error) => {
            console.error(error);
            notify("ra.notification.http_error", {
                type: "error"
            });
        })
            .then(() => unselectAll());
    }, [dataProvider, notify, resource, selectedIds, collidingIds]);
    const createMissingReferencedResources = useCallback(async () => {
        const { data: records } = await dataProvider.getMany(resource, { ids: selectedIds });
        return Promise.all(Object.entries(references).map(async ([field, reference]) => {
            const ids = [
                ...new Set(records.flatMap((record) => record[field]).filter(Boolean))
            ];
            return applyReferencedResources(dataProvider, ids, reference);
        }));
    }, [dataProvider, collidingIds, selectedIds, references]);
    const checkIdsCollisions = useCallback(() => {
        return dataProvider.getMany(appliedResource, { ids: selectedIds }).then((data) => data.data
            .filter((record) => {
            // even if the object does not exist, the method returns an id so we need to make sure there are other fields
            // this makes it so we cannot bulk apply templates that have only ids
            return Object.keys(record).length > 1;
        })
            .map(({ id }) => id));
    }, [dataProvider, selectedIds]);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: handleClick, label: "Apply templates", disabled: isCreateLoading || isUpdateLoading, children: _jsx(PostAddIcon, {}) }), _jsx(Confirm, { isOpen: applyConfirmOpen, title: "Are you sure you want to apply the templates?", content: `You have selected ${selectedIds.length} templates to apply. Please keep in mind that applying large number of templates at a time may take a while.`, confirm: "Apply", onConfirm: () => {
                    checkIdsCollisions()
                        .then((collidingIds) => {
                        return collidingIds.length
                            ? setCollidingIds(collidingIds)
                            : applyTemplates();
                    })
                        .then(() => setApplyConfirmOpen(false));
                }, onClose: () => setApplyConfirmOpen(false) }), _jsx(Confirm, { isOpen: collidingIds.length > 0, title: "Id collisions detected.", content: `We have found ${collidingIds.length} different documents that already exists. Would you like to continue, which will result in update of the relevant documents?`, onClose: () => setCollidingIds([]), confirm: "Update", onConfirm: () => applyTemplates().then(() => setCollidingIds([])) })] }));
};
