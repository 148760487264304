import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import { DeleteItemButton, exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { ListBulkActions } from "$components/List/ListBulkActions";
import { UserCell } from "$components/TableCells/UserCell";
const Admins = (props) => {
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, { customCreateLabel: "Add Admin" }), exporter: exporter("Admins"), ...props, children: _jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsx(ListBulkActions, {}), children: [_jsx(DeleteItemButton, {}), _jsx(TextField, { source: "id", label: "Auth id" }), _jsx(UserCell, { source: "id", label: "user" })] }) }));
};
export { Admins };
