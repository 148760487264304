import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { useResourceContext } from "react-admin";
import { NestedList } from "$components/NestedList/NestedList";
import { UserPointsListData } from "$components/Entities/UserPoints/UserPointsListData";
import { exportNestedList } from "$components/List/ListActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
const ChallengesContribution = (props) => {
    const resourceName = useResourceContext();
    return (_jsx(NestedList, { searchProps: { filter: { type: "GROUP" } }, searchField: "name", subCollection: "UsersPoints", resource: "Challenges", ...props, empty: _jsx(CardContent, { children: _jsx(Typography, { children: "Choose the challenge" }) }), exporter: exportNestedList("ChallengesUsersPoints"), children: _jsx(UserPointsListData, { model: "UserChallengeContribution", resourceName: resourceName }) }));
};
export { ChallengesContribution };
