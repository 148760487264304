import { styled } from "@mui/material/styles";
import { EmptyClasses } from "ra-ui-materialui";
/* I know that this could be optimized by splitting it into separate components instead of using the classes
 * However this is the official implementation of the ListEmptyContainer of the original react admin panel
 * We want to add additional action buttons to it without affecting the styling and theming, so we just copy it
 * Thanks to that we can still use all the theming options as before, and they will affect both out custom ListEmpty and the original
 */
export const ListEmptyContainer = styled("span", {
    name: "RaEmpty",
    overridesResolver: (props, styles) => styles.root
})(({ theme }) => ({
    flex: 1,
    [`& .${EmptyClasses.message}`]: {
        textAlign: "center",
        opacity: theme.palette.mode === "light" ? 0.5 : 0.8,
        margin: "0 1em",
        color: theme.palette.mode === "light" ? "inherit" : theme.palette.text.primary
    },
    [`& .${EmptyClasses.icon}`]: {
        width: "9em",
        height: "9em"
    },
    [`& .${EmptyClasses.toolbar}`]: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        marginTop: "2em"
    }
}));
