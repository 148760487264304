import { jsx as _jsx } from "react/jsx-runtime";
import { ImportButton } from "react-admin-import-csv";
import { useResourceContext } from "react-admin";
import { getMetaDataEntity } from "$components/List/ListData/ListData.utils";
import { transformRow, validateRow } from "./ImportData.utils";
import { RequireSuperAdmin } from "$components/Menu/RequireSuperAdmin";
export const ImportData = (props) => {
    const resource = useResourceContext();
    const entity = getMetaDataEntity(resource);
    if (!entity) {
        return null;
    }
    return (_jsx(RequireSuperAdmin, { children: _jsx(ImportButton, { ...props, disableGetMany: true, validateRow: (row) => validateRow(entity, row), transformRows: (rows) => {
                return rows.map((row) => transformRow(entity, row));
            } }) }));
};
