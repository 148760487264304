import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { FilterLiveSearch, List } from "react-admin";
import { exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { BadgesGrid } from "./BadgeGrid";
import { FilterSidebar, FilterSidebarTemplateCategory } from "$components/FilterSidebar";
import { CategoryFilter } from "$components/CategoryFilter";
const Badges = ({ isTemplate = false, ...props }) => {
    return (_jsx(List, { emptyWhileLoading: true, pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("Badges"), aside: _jsxs(FilterSidebar, { children: [_jsx(FilterLiveSearch, { name: "title", source: "title", label: "Title", alwaysOn: true }), isTemplate && _jsx(FilterSidebarTemplateCategory, {}), _jsx(CategoryFilter, { type: "BADGE", source: "rankKey", isTemplate: isTemplate })] }), sort: { field: "rankKey", order: "ASC" }, ...props, children: _jsx(BadgesGrid, { isTemplate: isTemplate }) }));
};
export { Badges };
