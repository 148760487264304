import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { AutocompleteInput, Datagrid, DateField, EditButton, List, ReferenceField, ReferenceInput, RichTextField, SelectInput, TextField } from "react-admin";
import { DeleteItemButton, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { exporter } from "$components/List/ListActions/ExportData";
import { ListBulkActions } from "$components/List/ListBulkActions";
import { UserCell } from "$components/TableCells/UserCell";
import DialogContentText from "@mui/material/DialogContentText";
const UserTask = (props) => {
    return (_jsx(List, { sort: { field: "finishDate" }, pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, { hasCreate: false }), exporter: exporter("UserTasks"), filters: [
            _jsx(ReferenceInput, { label: "User", source: "userId", reference: "Users", alwaysOn: true, children: _jsx(SelectInput, { optionText: "username", size: "medium", variant: "filled", sx: { width: "250px" } }) }),
            _jsx(ReferenceInput, { label: "Task", source: "taskId", reference: "Tasks", alwaysOn: true, children: _jsx(AutocompleteInput, { optionText: "name", size: "medium", variant: "filled", sx: { width: "500px" }, filterToQuery: (q) => ({ name: q }) }) })
        ], ...props, children: _jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsx(ListBulkActions, {}), children: [_jsx(EditButton, { label: "", size: "medium" }), _jsx(DeleteItemButton, { customConfirmTitle: "Are you sure you want to delete this task submission?", customConfirmContent: _jsxs(_Fragment, { children: [_jsx(DialogContentText, { sx: { marginBottom: "1em" }, children: "You can\u2019t undo this action." }), _jsx(DialogContentText, { children: "Important note: If the task is a ValueFeed type task, then the Value Feed submission (ValueBit) has to be deleted separately in the ValueFeed section of the admin panel." })] }) }), _jsx(ReferenceField, { label: "Task Name", reference: "Tasks", source: "taskId", children: _jsx(TextField, { source: "name" }) }), _jsx(DateField, { source: "finishDate" }), _jsx(UserCell, { source: "userId" }), _jsx(RichTextField, { source: "comment" })] }) }));
};
export { UserTask };
