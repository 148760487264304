export async function applyReferencedResources(dataProvider, ids, reference) {
    if (ids.length === 0)
        return [];
    const existingElementsIds = await dataProvider
        .getMany(reference.appliedResource, { ids })
        .then(({ data }) => data
        // get many returns a record with just an id if it doesn't exist, so filtering for resources with more fields
        // I know that I could filter it the opposite way to get list of ids that don't exist,
        // but I think this mechanic is a bug and is subject to future changes
        .filter((record) => Object.keys(record).length > 1)
        .map(({ id }) => id));
    const nonExistingIds = ids.filter((id) => !existingElementsIds.includes(id));
    const nonExistingRecords = await dataProvider.getMany(reference.templateResource, {
        ids: nonExistingIds
    });
    const createdResources = await Promise.all(nonExistingRecords.data.map((record) => dataProvider.create(reference.appliedResource, { data: record })));
    if (reference.references) {
        const createdSubResources = await Promise.all(Object.entries(reference.references).map(([field, subReference]) => {
            const ids = [
                ...new Set(nonExistingRecords.data.flatMap((record) => record[field]).filter(Boolean))
            ];
            return applyReferencedResources(dataProvider, ids, subReference);
        }));
        return [...createdResources, ...createdSubResources.flat()];
    }
    return createdResources;
}
