import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Datagrid, EditButton, List, NumberField, ReferenceField, TextField, useDataProvider } from "react-admin";
import { query, where } from "firebase/firestore";
import { DeleteItemButton, exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { ListBulkActions } from "$components/List/ListBulkActions";
import React, { useEffect, useState } from "react";
import { ColorField } from "$components/ColorPicker";
import Box from "@mui/material/Box";
import { metadata } from "$utils/metadata.utils";
import { LevelCoins } from "./LevelCoins";
const LevelInfos = (props) => {
    const dataProvider = useDataProvider();
    const [coinsPer250Points, setCoinsPer250Points] = useState(null);
    useEffect(() => {
        setCoinsPer250Points(null);
        dataProvider
            .getOne("CoinLevelSettings", { id: "SETTINGS" })
            .then((settings) => setCoinsPer250Points(settings.data.coinsPer250Points))
            .catch(() => setCoinsPer250Points(Number(metadata.CoinLevelSettings.__defaultValues.coinsPer250Points)));
    }, [dataProvider]);
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("LevelInfos"), ...props, sort: { field: "lvl", order: "ASC" }, filter: {
            // Hiding unreachable level 0 from the list
            collectionQuery: (ref) => query(ref, where("lvl", ">=", 1))
        }, children: _jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsx(ListBulkActions, {}), children: [_jsx(EditButton, { label: "", size: "medium" }), _jsx(DeleteItemButton, {}), _jsx(NumberField, { source: "lvl" }), _jsx(NumberField, { source: "minExp", label: "Total Points Required" }), _jsx(LevelCoins, { coinsPer250Points: coinsPer250Points }), _jsx(ReferenceField, { reference: "LevelColors", source: "colors", label: "Colours", children: _jsxs(Box, { sx: { display: "flex" }, children: [_jsx(TextField, { source: "id" }), _jsx(ColorField, { source: "foregroundColor" }), _jsx(ColorField, { source: "backgroundColor" })] }) })] }) }));
};
export { LevelInfos };
