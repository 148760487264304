import { jsx as _jsx } from "react/jsx-runtime";
import { MenuItem } from "$components/Menu/Menu.styled";
import React from "react";
import { useGetOne, useResourceDefinitionContext } from "react-admin";
/**
 * Button for entities with a single SETTINGS entry
 * redirects to the edit form of an existing one or creation of a new one if it does not exist
 * @param {string} resource Name of the entity resource
 * @param {string} customId Id of the config variable
 * @param {Omit<MenuItemLinkProps, "to" | "disabled">} menuItemProps Props for the button
 */
export const SettingsButton = ({ name, customId, subPath, ...menuItemProps }) => {
    const id = customId ?? "SETTINGS";
    const { isLoading, data } = useGetOne(name, { id }, { retry: false });
    const settingExist = data && Object.keys(data).length > 1;
    const resources = useResourceDefinitionContext();
    // use icon and label from resource definition if not overwritten directly via menuItemProps
    const Icon = resources.definitions[name]?.icon;
    const label = resources.definitions[name]?.options?.label;
    return (_jsx(MenuItem, { leftIcon: Icon && _jsx(Icon, {}), primaryText: label, ...menuItemProps, to: `/${name}/${settingExist ? id : "create"}${subPath ? `/${subPath}` : ""}`, disabled: isLoading }));
};
