import { jsx as _jsx } from "react/jsx-runtime";
import { SimpleForm, Create, Edit, useResourceContext } from "react-admin";
import * as pluralize from "pluralize";
import { AutocompleteCell } from "$components/TableCells/AutocompleteCell";
import { getInputComponent } from "$utils/getInputComponent";
import { getReferenceFieldName } from "./EditAndCreateBase.utils";
import metadata from "$public/metadata.json";
export function renderFields({ id, ...currentEntity }) {
    if (!metadata) {
        return null;
    }
    //! temporary set to any
    return Object.entries(currentEntity).map(([key, { source, type, modelName, isArray, ...rest }]) => {
        if (!modelName) {
            if (Array.isArray(type)) {
                const availableChoices = type.map((value) => ({ id: value, name: value }));
                return (_jsx(AutocompleteCell, { source: source, choices: availableChoices }, source));
            }
            const CellComponent = getInputComponent(type, source);
            return _jsx(CellComponent, { source: source, fullWidth: true, ...rest }, source);
        }
        else {
            const referenceFieldName = getReferenceFieldName(metadata[modelName], modelName);
            return (_jsx(AutocompleteCell, { source: key, modelName: modelName, collectionReference: pluralize(modelName), referenceField: referenceFieldName, referenceId: referenceFieldName, multichoice: isArray }, key));
        }
    });
}
const EditAndCreateBase = ({ variant, variantProps }) => {
    const entityName = useResourceContext();
    const FormWrapper = variant === "create" ? Create : Edit;
    const { __defaultValues = {}, ...currentEntity } = metadata[pluralize.singular(entityName)] || {};
    return (_jsx(FormWrapper, { ...variantProps, children: _jsx(SimpleForm, { defaultValues: __defaultValues, children: renderFields(currentEntity) }) }));
};
export { EditAndCreateBase };
