import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Create, Edit, SaveButton, SimpleForm, Toolbar } from "react-admin";
import metadata from "$public/metadata.json";
import { BadgesFormFields } from "./BadgesFormFields";
export const BadgesEdit = ({ variant, variantProps, isTemplate = false }) => {
    const FormWrapper = variant === "create" ? Create : Edit;
    const { __defaultValues = {} } = metadata.Badge;
    return (_jsx(FormWrapper, { redirect: "list", ...variantProps, children: _jsx(SimpleForm, { defaultValues: __defaultValues, toolbar: _jsx(Toolbar, { children: _jsx(SaveButton, {}) }), children: _jsx(BadgesFormFields, { isTemplate: isTemplate }) }) }));
};
