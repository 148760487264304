import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import RefreshIcon from "@mui/icons-material/Refresh";
import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";
import { getStorage, listAll, ref } from "firebase/storage";
import { Confirm } from "react-admin";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
export const RestoreBackupButton = () => {
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [backups, setBackups] = useState([]);
    const [chosenBackup, setChosenBackup] = useState("");
    const restoreTrigger = httpsCallable(getFunctions(getApp(), "europe-central2"), "callBackupRestore");
    return (_jsxs(_Fragment, { children: [_jsx(Confirm, { isOpen: isConfirmationOpen, loading: !Boolean(backups.length), content: _jsx(Select, { value: chosenBackup, onChange: (el) => setChosenBackup(el.target.value), fullWidth: true, children: backups.map((backup) => (_jsx(MenuItem, { value: backup, children: backup }, backup))) }), onClose: () => setIsConfirmationOpen(false), onConfirm: () => {
                    restoreTrigger({ fileName: chosenBackup }).then(() => setIsConfirmationOpen(false));
                }, title: "Select Backup to restore" }), _jsxs(MenuItem, { onClick: () => {
                    setIsConfirmationOpen(true);
                    listAll(ref(getStorage(), "Backups")).then((list) => setBackups(list.prefixes.map((prefix) => prefix.name)));
                }, children: [_jsx(ListItemIcon, { style: { minWidth: "40px" }, children: _jsx(RefreshIcon, {}) }), _jsx(Typography, { variant: "inherit", color: "textSecondary", children: "Restore Backup" })] })] }));
};
