import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { AutocompleteInput, BooleanInput, BulkExportButton, BulkUpdateButton, Datagrid, DateField, List, NumberField, ReferenceField, ReferenceInput, TextField } from "react-admin";
import { exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { UserCell } from "$components/TableCells/UserCell";
import { ConditionalModifyButton } from "$components/List/ListActions/ConditionalModifyButton";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
export const StorePublicItemContribution = (props) => {
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("StorePublicItemContributions"), filters: [
            _jsx(ReferenceInput, { label: "User", source: "userId", reference: "Users", alwaysOn: true, children: _jsx(AutocompleteInput, { optionText: "username", filterToQuery: (q) => ({ username: q }) }) }),
            _jsx(ReferenceInput, { label: "Store Item", source: "itemId", reference: "StorePublicItems", alwaysOn: true, children: _jsx(AutocompleteInput, { optionText: "title", filterToQuery: (q) => ({ title: q }) }) }),
            _jsx(BooleanInput, { source: "refunded", label: "Show Refunded (Only)", alwaysOn: true, options: {} })
        ], ...props, sort: { field: "contributionDate", order: "DESC" }, children: _jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsxs(_Fragment, { children: [_jsx(BulkUpdateButton, { label: "Refund", data: { refunded: true }, icon: _jsx(CurrencyExchangeIcon, {}), mutationMode: "pessimistic" }), _jsx(BulkExportButton, {})] }), children: [_jsx(ReferenceField, { reference: "StorePublicItems", source: "itemId", children: _jsx(TextField, { source: "title", label: "Store Item Title" }) }), _jsx(UserCell, { source: "userId" }), _jsx(NumberField, { source: "contributedCoins" }), _jsx(DateField, { source: "contributionDate" }), _jsx(ConditionalModifyButton, { source: "refundStatus", label: "Refund Status", tooltip: "Refund", tooltipPlacement: "content", icon: null, changedData: { refunded: true }, isDisabled: (record) => Boolean(record["refunded"]) })] }) }));
};
