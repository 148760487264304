import { styled } from "@mui/system";
export const BadgeBackground = styled("div")({
    margin: "12px",
    height: "48px",
    width: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    background: "rgb(226, 232, 240)",
    transform: "rotate(45deg)"
});
export const BadgeImageWrapper = styled("div")({
    transform: "rotate(-45deg)"
});
export const BadgeImage = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "cover"
});
export const LottieBadgeWrapper = styled("div")((props) => ({
    height: "48px",
    width: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "& svg": {
        "& image": {
            filter: props.isLocked
                ? "brightness(0) grayscale(100%) invert(50%) sepia(7%) saturate(1287%) hue-rotate(177deg) contrast(79%)"
                : "none"
        },
        "& path": {
            fill: props.isLocked ? "rgb(113, 128, 150)" : "",
            stroke: props.isLocked ? "rgb(113, 128, 150)" : ""
        }
    }
}));
