import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BulkExportButton, Datagrid, EditButton, useListContext } from "react-admin";
import { renderTextFields } from "./ListData.utils";
import { ListBulkActions } from "$components/List/ListBulkActions";
import { DeleteItemButton } from "$components/List/ListActions";
const ListData = ({ isEditable = true, isRemovable = true, customConfirmTitle, customConfirmContent }) => {
    const { data, resource } = useListContext();
    return (_jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: isRemovable ? _jsx(ListBulkActions, {}) : _jsx(BulkExportButton, {}), children: [isEditable && _jsx(EditButton, { label: "", size: "medium" }), isRemovable && (_jsx(DeleteItemButton, { customConfirmTitle: customConfirmTitle, customConfirmContent: customConfirmContent })), data && renderTextFields(resource)] }));
};
export default ListData;
