import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
export const FilterSidebar = ({ children }) => (_jsx(Box, { sx: {
        display: {
            xs: "none",
            sm: "block"
        },
        order: -1,
        width: "15em",
        marginRight: "1em"
    }, children: _jsx(Card, { children: _jsx(CardContent, { children: children }) }) }));
