import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { cloneElement } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, FormDataConsumer, ListBase, ListToolbar, Title } from "react-admin";
import nestedListFilter from "$components/NestedList/NestedListFilter";
import { Pagination } from "$components/Pagination";
import { NestedListSearch } from "./NestedListSearch";
import Card from "@mui/material/Card";
export const NestedList = (props) => {
    const { exporter, children, actions, filters, title, filter, searchField, subCollection, pagination, empty, searchProps, ...other } = props;
    const searchFieldSource = `${other.resource}_${searchField ?? "id"}`;
    const [searchParams] = useSearchParams(window.location.search);
    const defaultSelectedSearch = searchParams.get("id") ?? undefined;
    return (_jsx(Form, { children: _jsxs(Card, { children: [_jsx(NestedListSearch, { reference: other.resource, source: searchFieldSource, searchField: searchField, searchProps: searchProps }), _jsx(FormDataConsumer, { children: ({ formData }) => {
                        // default value for the form input is not passed to the formDataConsumer
                        const selectedSearch = formData[searchFieldSource] ?? defaultSelectedSearch;
                        if (!selectedSearch) {
                            return empty ?? null;
                        }
                        const absoluteCollectionPath = `${other.resource}/${selectedSearch}/${subCollection}`;
                        return (_createElement(ListBase, { ...other, key: selectedSearch, filter: {
                                ...filter,
                                ...nestedListFilter(selectedSearch ?? undefined, subCollection)
                            }, disableSyncWithLocation: true, exporter: exporter && exporter(absoluteCollectionPath) },
                            _jsx(Title, { title: title ?? subCollection }),
                            _jsx(ListToolbar, { filters: filters, actions: actions }),
                            cloneElement(children, {
                                absoluteCollectionPath
                            }),
                            pagination ?? _jsx(Pagination, {})));
                    } })] }) }));
};
