import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useGetList } from "react-admin";
import Badge from "@mui/material/Badge";
import StoreIcon from "@mui/icons-material/StoreMallDirectory";
export const StorePublicItemsIcon = () => {
    // react admin data provider for firebase does not allow inequality comparison without using collectionQueries
    // collectionQueries do not support live updates, so updating notification count would require page refresh
    // via ordering by date we move the non gathered items to the back of the list,
    // meaning that all gathered unprocessed items will be present first, as such we can safely filter it afterwards
    const { data: unprocessedItems, isLoading } = useGetList("StorePublicItems", {
        pagination: { page: 1, perPage: 10 },
        sort: { field: "gatheredDate", order: "DESC" },
        filter: { processed: false }
    });
    const itemsCounts = unprocessedItems?.filter(({ gatheredDate }) => Boolean(gatheredDate)).length;
    return (_jsx(Badge, { sx: { "& .MuiBadge-badge": { fontSize: 12, height: 15, minWidth: 15 } }, badgeContent: itemsCounts, color: "error", invisible: isLoading, max: 9, children: _jsx(StoreIcon, {}) }));
};
