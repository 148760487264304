import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Edit, SimpleForm, SaveButton, Create, TextInput, RadioButtonGroupInput, BooleanInput } from "react-admin";
import metadata from "$public/metadata.json";
import Toolbar from "@mui/material/Toolbar";
import { chatFeedIntegrationTypeChoices } from "$constants/chatFeedIntegrationTypeChoices";
export const ChatFeedIntegrationsEdit = ({ variant, variantProps }) => {
    const idEdit = variant === "edit";
    const FormWrapper = idEdit ? Edit : Create;
    const { __defaultValues = {} } = metadata.ChatFeedIntegration;
    return (_jsx(FormWrapper, { ...variantProps, redirect: null, children: _jsxs(SimpleForm, { defaultValues: __defaultValues, toolbar: _jsx(Toolbar, { children: _jsx(SaveButton, {}) }), children: [_jsx(TextInput, { source: "name", fullWidth: true, required: true, helperText: "Friendly name that will help with distinguishing integration" }), _jsx(TextInput, { source: "integrationLink", fullWidth: true, required: true, disabled: idEdit }), _jsx(RadioButtonGroupInput, { source: "integrationType", choices: chatFeedIntegrationTypeChoices, defaultValue: chatFeedIntegrationTypeChoices[0].id, disabled: idEdit, required: true }), _jsx(BooleanInput, { source: "enabled" })] }) }));
};
