import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from "react";
import { Confirm, useDataProvider } from "react-admin";
import ConstructionIcon from "@mui/icons-material/Construction";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
const confirmPopupTexts = {
    titleText: {
        on: `Are you sure you want to turn the  maintenance mode "on"?`,
        off: `Are you sure you want to turn the  maintenance mode "off"?`
    },
    contentText: {
        on: "Turning the maintenance mode on will prevent the players from accessing the app. " +
            "Players with admin access will still have the possibility to use the app.",
        off: "Turning the maintenance mode off will enable the players to access the app."
    }
};
export const MaintenanceModeButton = () => {
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [settings, setSettings] = useState(null);
    const dataProvider = useDataProvider();
    useEffect(() => {
        dataProvider
            .getOne("GamespaceSettings", { id: "SETTINGS" })
            .then(({ data }) => setSettings(data));
    }, [dataProvider]);
    const toggleMaintenanceMode = useCallback(() => {
        if (!settings)
            return;
        const newConfig = { ...settings, maintenanceMode: !settings.maintenanceMode };
        return dataProvider
            .update("GamespaceSettings", {
            id: "SETTINGS",
            data: newConfig,
            previousData: settings
        })
            .then(() => {
            setSettings(newConfig);
            setIsConfirmationOpen(false);
        });
    }, [dataProvider, settings]);
    const confirmVariant = settings?.maintenanceMode ? "off" : "on";
    return (_jsxs(_Fragment, { children: [_jsx(Confirm, { isOpen: isConfirmationOpen, content: confirmPopupTexts.contentText[confirmVariant], onClose: () => setIsConfirmationOpen(false), onConfirm: toggleMaintenanceMode, title: confirmPopupTexts.titleText[confirmVariant] }), _jsxs(MenuItem, { onClick: () => setIsConfirmationOpen(true), children: [_jsx(ListItemIcon, { style: { minWidth: "40px" }, children: _jsx(ConstructionIcon, {}) }), _jsx(Typography, { variant: "inherit", color: "textSecondary", children: "Maintenance" }), _jsx(Switch, { edge: "end", checked: Boolean(settings?.maintenanceMode) })] })] }));
};
