import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Create, Edit, SimpleForm, TextInput, required, NumberInput } from "react-admin";
import metadata from "$public/metadata.json";
import { getInputComponent } from "$utils/getInputComponent";
export const CoinLeaderboardsEdit = ({ variant, variantProps }) => {
    const FormWrapper = variant === "create" ? Create : Edit;
    const { __defaultValues = {} } = metadata.CoinLeaderboard;
    const StartDateInput = getInputComponent("date", "startDate");
    const EndDateInput = getInputComponent("date", "endDate");
    return (_jsx(FormWrapper, { ...variantProps, children: _jsxs(SimpleForm, { defaultValues: __defaultValues, children: [_jsx(TextInput, { source: "name", validate: required(), fullWidth: true, helperText: "Ex. July 2022" }), _jsx(StartDateInput, { source: "startDate", validate: required(), fullWidth: true }), _jsx(EndDateInput, { source: "endDate", validate: required(), fullWidth: true }), _jsx(NumberInput, { source: "totalCoins", label: "Total Coins", fullWidth: true }), _jsx(NumberInput, { source: "totalLevelCoins", label: "Leveling Coins", fullWidth: true }), _jsx(NumberInput, { source: "totalMonthlyChallengeCoins", label: "Individual Challenges' Coins", fullWidth: true }), _jsx(NumberInput, { source: "totalGroupChallengeCoins", label: "Group Challenges' Coins", fullWidth: true })] }) }));
};
