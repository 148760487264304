import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useGetOne, useRecordContext } from "react-admin";
import { TextBadgeContainer } from "./BadgeTitle.styled";
export const BadgeTitle = ({ isTemplate }) => {
    const record = useRecordContext();
    const { data, isLoading } = useGetOne(isTemplate ? "CategoriesTemplates" : "Categories", { id: record?.rankKey });
    if (!record)
        return null;
    return (_jsx(TextBadgeContainer, { bgColor: isLoading || !data?.color ? "gray" : data.color, children: record.title }));
};
