import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { AutocompleteInput, ReferenceInput, NumberInput, SelectInput, SimpleForm, required, Toolbar, SaveButton } from "react-admin";
import { NestedEdit } from "$components/NestedList/NestedEditAndCreateBase";
import metadata from "$public/metadata.json";
import { challengeStatusChoices } from "$constants/challengeStatusChoices";
export const IndividualChallengesEdit = (editProps) => {
    const { __defaultValues = {} } = metadata.UserIndividualChallenge;
    return (_jsx(NestedEdit, { ...editProps, children: _jsxs(SimpleForm, { defaultValues: __defaultValues, toolbar: _jsx(Toolbar, { children: _jsx(SaveButton, {}) }), children: [_jsx(ReferenceInput, { source: "challengeKey", reference: "Challenges", filter: { type: "INDIVIDUAL" }, alwaysOn: true, children: _jsx(AutocompleteInput, { label: "Challenge name", optionText: "name", filterToQuery: (q) => ({ name: q }), validate: required(), fullWidth: true }) }), _jsx(NumberInput, { source: "points", validate: required(), fullWidth: true }), _jsx(SelectInput, { source: "status", choices: challengeStatusChoices, validate: required(), fullWidth: true })] }) }));
};
