import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useDataProvider, useListContext, useRecordContext } from "react-admin";
export const LevelCoins = ({ coinsPer250Points }) => {
    const { id, lvl, minExp } = useRecordContext();
    const { data } = useListContext();
    const dataProvider = useDataProvider();
    const [minExpForEarlierLevel, setMinExpForEarlierLevel] = useState(null);
    useEffect(() => {
        if (lvl <= 1)
            return setMinExpForEarlierLevel(minExp);
        const lowerLevelId = String(Number(id) - 1);
        const lowerLevelFromListContext = data.find(({ id }) => id === lowerLevelId);
        if (lowerLevelFromListContext) {
            return setMinExpForEarlierLevel(lowerLevelFromListContext.minExp);
        }
        dataProvider
            .getOne("LevelInfos", { id: lowerLevelId })
            .then(({ data }) => setMinExpForEarlierLevel(data.minExp));
    }, [dataProvider]);
    if (!minExpForEarlierLevel || !coinsPer250Points)
        return null;
    return _jsx("span", { children: Math.round(((minExp - minExpForEarlierLevel) / 250) * coinsPer250Points) });
};
// react-admin Datagrid uses values from component props to render table column headers
// there is no other way to set the default values for them without accessing the defaultProps property
LevelCoins.defaultProps = { label: "Coins", textAlign: "right" };
