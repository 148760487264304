import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { Button, SaveButton, useCreate, useNotify, Form } from "react-admin";
import IconContentAdd from "@mui/icons-material/Add";
import IconCancel from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import metadata from "$public/metadata.json";
import * as pluralize from "pluralize";
import { useFormContext } from "react-hook-form";
export const QuickCreate = ({ children, reference, source }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { isLoading }] = useCreate(reference);
    const notify = useNotify();
    const { setValue } = useFormContext();
    const handleClick = () => setShowDialog(true);
    const handleCloseClick = () => setShowDialog(false);
    const handleSubmit = async (values) => {
        await create(reference, { data: values }, {
            onSuccess: (data) => {
                setShowDialog(false);
                // Update the comment form to target the newly created post
                // Updating the ReferenceInput value will force it to reload the available posts
                setValue(source, data.id, { shouldDirty: true });
            },
            onError: ({ error }) => {
                notify(error.message, { type: "error" });
            }
        });
    };
    const singularRef = pluralize.singular(reference);
    const { __defaultValues = {} } = metadata[singularRef] || {};
    return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: handleClick, label: "ra.action.create", variant: "contained", sx: {
                    position: "absolute",
                    top: "8px",
                    left: 0,
                    height: "48px",
                    borderRadius: "4px 0 0 4px",
                    boxShadow: "none"
                }, children: _jsx(IconContentAdd, {}) }), _jsxs(Dialog, { fullWidth: true, open: showDialog, onClose: handleCloseClick, "aria-label": `Create ${singularRef}`, children: [_jsx(DialogTitle, { children: `Create ${singularRef}` }), _jsxs(Form, { resource: reference, onSubmit: handleSubmit, defaultValues: __defaultValues, record: {}, children: [_jsx(DialogContent, { children: children }), _jsxs(DialogActions, { children: [_jsx(Button, { label: "ra.action.cancel", onClick: handleCloseClick, disabled: isLoading, children: _jsx(IconCancel, {}) }), _jsx(SaveButton, { disabled: isLoading })] })] })] })] }));
};
