import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from "react";
import { useCreate, useDataProvider, useRecordContext, Confirm, useUpdate } from "react-admin";
import Button from "@mui/material/Button";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Tooltip from "@mui/material/Tooltip";
import { applyReferencedResources } from "$components/List/ListActions/ApplyTemplateButton/ApplyTemplateButton.utils";
export const ApplyTemplateButton = ({ appliedResource, references = {} }) => {
    const dataProvider = useDataProvider();
    const { templateCategory, ...record } = useRecordContext();
    const [applyConfirmOpen, setApplyConfirmOpen] = useState(false);
    const [updateConfirmOpen, setUpdateConfirmOpen] = useState(false);
    const [create, { isLoading: isCreateLoading }] = useCreate();
    const [update, { isLoading: isUpdateLoading }] = useUpdate();
    const checkIdCollision = useCallback(() => {
        return dataProvider
            .getOne(appliedResource, { id: record.id })
            .then(() => true)
            .catch(() => false);
    }, [dataProvider, record]);
    const createMissingReferencedResources = useCallback(() => {
        return Promise.all(Object.entries(references).map(async ([field, reference]) => {
            const ids = Array.isArray(record[field]) ? record[field] : [record[field]];
            return applyReferencedResources(dataProvider, ids, reference);
        }));
    }, [dataProvider, record]);
    return (_jsx(Tooltip, { title: "Apply template", disableInteractive: true, children: _jsxs(Button, { disabled: isCreateLoading || isUpdateLoading, onClick: () => setApplyConfirmOpen(true), children: [_jsx(PostAddIcon, {}), _jsx(Confirm, { isOpen: applyConfirmOpen, title: "Are you sure you want to apply the template?", content: "Applying the template will also apply all the templates references by the template being applied.", confirm: "Apply", onConfirm: () => checkIdCollision()
                        .then(async (isColliding) => {
                        if (isColliding)
                            return setUpdateConfirmOpen(true);
                        await create(appliedResource, { data: record }, { returnPromise: true });
                        await createMissingReferencedResources();
                    })
                        .then(() => setApplyConfirmOpen(false)), onClose: () => setApplyConfirmOpen(false) }), _jsx(Confirm, { isOpen: updateConfirmOpen, title: "Id collision detected.", content: "Document with a given id already exists. Would you like to update it?", onClose: () => setUpdateConfirmOpen(false), confirm: "Update", onConfirm: () => update(appliedResource, { id: record.id, data: record }, { returnPromise: true })
                        .then(() => createMissingReferencedResources())
                        .then(() => setUpdateConfirmOpen(false)) })] }) }));
};
