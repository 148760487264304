import { jsx as _jsx } from "react/jsx-runtime";
import { List } from "react-admin";
import ListData from "$components/List/ListData";
import { exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
const LevelColors = (props) => {
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("LevelColors"), ...props, children: _jsx(ListData, { isRemovable: false }) }));
};
export { LevelColors };
