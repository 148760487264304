import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { FilterList, FilterListItem, useInfiniteGetList, useResourceContext } from "react-admin";
import uniqBy from "lodash.uniqby";
export const FilterSidebarTemplateCategory = () => {
    const resource = useResourceContext();
    const { isLoading, data: currentTemplates } = useInfiniteGetList(resource, { sort: { order: "ASC", field: "templateCategory" } });
    const categoriesFromTemplates = uniqBy(currentTemplates?.pages.flatMap(({ data }) => data) ?? [], "templateCategory")
        .map(({ templateCategory }) => templateCategory)
        .filter(Boolean);
    return (_jsx(FilterList, { label: "Template Category", icon: "", children: !isLoading &&
            categoriesFromTemplates.map((category) => (_jsx(FilterListItem, { label: category, value: { templateCategory: category } }, category))) }));
};
