import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { useInput } from "react-admin";
import { useFormContext } from "react-hook-form";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
const CustomInputComponent = React.forwardRef(function CustomInputComponent({ itemList, deleteItem, ...props }, ref) {
    return (_jsxs(_Fragment, { children: [itemList.map((item, index) => (_jsx(Chip, { sx: {
                    marginLeft: index === 0 ? "14px" : "5px"
                }, label: item, size: "small", onDelete: () => deleteItem(index) }, item + index))), _jsx("input", { ...props, ref: ref })] }));
});
export const CommaSeparatedList = ({ source, helperText, label = source, isRequired }) => {
    const { field, fieldState: { isTouched, invalid, error }, formState: { isSubmitted } } = useInput({
        source,
        label,
        isRequired,
        parse: (commaSeparatedList) => {
            if (!commaSeparatedList)
                return [];
            return commaSeparatedList.split(",").map((element) => element.trim().toLowerCase());
        },
        format: (listFromDB) => Array.isArray(listFromDB) ? listFromDB.join(",") : listFromDB
    });
    const form = useFormContext();
    const itemList = field.value ? field.value.split(",") : [];
    const [currentElement, setCurrentElement] = useState("");
    return (_jsx(TextField, { fullWidth: true, ...field, value: currentElement, onChange: (changeEvent) => {
            // tracking input being typed
            const listItems = changeEvent.target.value.split(",");
            const currentItem = listItems.pop();
            setCurrentElement(currentItem);
            // tracking formState list of items
            changeEvent.target.value = [...itemList, ...listItems].join(",");
            field.onChange(changeEvent);
        }, label: label, error: (isTouched || isSubmitted) && invalid, helperText: (isTouched || isSubmitted) && invalid ? error.message : helperText, onBlur: () => {
            if (!currentElement)
                return;
            // turning last typed element into list item
            form.setValue(source, [...itemList, currentElement]);
            setCurrentElement("");
        }, InputLabelProps: 
        // pass extra shrink prop to minimize the label when there are any list items
        // (data is present in the formState but not the input itself)
        itemList.length > 0 ? { shrink: true } : {}, InputProps: {
            inputProps: {
                itemList,
                deleteItem: (index) => {
                    form.setValue(source, [
                        ...itemList.slice(0, index),
                        ...itemList.slice(index + 1)
                    ]);
                }
            },
            inputComponent: CustomInputComponent
        } }));
};
