import { BulkActionsToolbarClasses, defaultTheme } from "react-admin";
import merge from "lodash.merge";
import { createTheme } from "@mui/system";
const muiTheme = createTheme();
export const theme = {
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    maxWidth: 250,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden"
                },
                head: {
                    fontWeight: "bold",
                    textTransform: "uppercase"
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: "7px !important"
                }
            }
        },
        RaListToolbar: {
            styleOverrides: {
                root: {
                    [muiTheme.breakpoints.up("xs")]: {
                        flexDirection: "column",
                        alignItems: "flex-start",
                        minHeight: "initial"
                    }
                }
            }
        },
        RaTopToolbar: {
            styleOverrides: {
                root: {
                    [muiTheme.breakpoints.up("xs")]: {
                        minHeight: "initial",
                        flex: "initial"
                    }
                }
            }
        },
        RaBulkActionsToolbar: {
            styleOverrides: {
                root: {
                    [`& .${BulkActionsToolbarClasses.toolbar}`]: {
                        justifyContent: "left"
                    },
                    [`& .${BulkActionsToolbarClasses.title}`]: {
                        height: "1.4em"
                    }
                }
            }
        },
        RaFilterForm: {
            styleOverrides: {
                root: {
                    alignItems: "center"
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                spacer: {
                    flex: "none"
                },
                toolbar: {
                    [muiTheme.breakpoints.up("sm")]: {
                        paddingLeft: "16px"
                    }
                }
            }
        },
        RaRichTextInput: {
            styleOverrides: {
                root: {
                    "& .RaRichTextInput-editorContent": {
                        "& > div": {
                            minHeight: "5em"
                        }
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    whiteSpace: "break-spaces"
                }
            }
        },
        RaEditButton: {
            styleOverrides: {
                root: {
                    height: 36
                }
            }
        },
        RaDatagrid: {
            styleOverrides: {
                root: {
                    // action buttons don't have labels so they are classified as undefined column
                    // we want to reduce the space they take up
                    "& .column-undefined": {
                        padding: 0,
                        width: 64
                    }
                }
            }
        }
    }
};
export const lightTheme = merge({}, defaultTheme, theme, {
    palette: { mode: "light" }
});
export const darkTheme = merge({}, theme, {
    palette: { mode: "dark" }
});
