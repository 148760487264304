import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Datagrid, DateField, EditButton, List, NumberField, TextField } from "react-admin";
import { DeleteItemButton, exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { ListBulkActions } from "$components/List/ListBulkActions";
const CoinLeaderboards = (props) => {
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("CoinLeaderboards"), ...props, children: _jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsx(ListBulkActions, {}), children: [_jsx(EditButton, { label: "", size: "medium" }), _jsx(DeleteItemButton, {}), _jsx(TextField, { source: "name" }), _jsx(DateField, { source: "startDate" }), _jsx(DateField, { source: "endDate" }), _jsx(NumberField, { source: "totalCoins", label: "Total" }), _jsx(NumberField, { source: "totalLevelCoins", label: "Leveling" }), _jsx(NumberField, { source: "totalMonthlyChallengeCoins", label: "Individual Challenges" }), _jsx(NumberField, { source: "totalGroupChallengeCoins", label: "Group Challenges" })] }) }));
};
export { CoinLeaderboards };
