import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { AutocompleteInput, ReferenceInput, NumberInput, SimpleForm, useRecordContext } from "react-admin";
import { NestedEdit } from "$components/NestedList/NestedEditAndCreateBase";
import metadata from "$public/metadata.json";
import { useFormContext } from "react-hook-form";
export const UserPointsPointInputs = () => {
    // TODO: update leaderboard total on edits to user points
    const record = useRecordContext();
    const { setValue, watch } = useFormContext();
    const points = watch("points");
    const taskPoints = watch("taskPoints");
    const challengePoints = watch("challengePoints");
    // backwards compatibility for leaderboards created before challenges were giving points (only tracked total)
    useEffect(() => {
        if (!record)
            return;
        if (record.taskPoints === undefined && !taskPoints) {
            setValue("taskPoints", points);
        }
    }, [record, points, setValue]);
    // updating total based on sub values
    useEffect(() => {
        setValue("points", taskPoints + challengePoints);
    }, [taskPoints, challengePoints]);
    return (_jsxs(_Fragment, { children: [_jsx(NumberInput, { source: "points", label: "Total Points", disabled: true }), _jsx(NumberInput, { source: "taskPoints" }), _jsx(NumberInput, { source: "challengePoints" })] }));
};
export const UserPointsEdit = (editProps) => {
    const { __defaultValues = {} } = metadata.UserPoints;
    return (_jsx(NestedEdit, { ...editProps, children: _jsxs(SimpleForm, { defaultValues: __defaultValues, children: [_jsx(ReferenceInput, { label: "User", source: "userId", name: "userId", reference: "Users", alwaysOn: true, children: _jsx(AutocompleteInput, { optionText: "username", fullWidth: true, disabled: true }) }), _jsx(UserPointsPointInputs, {})] }) }));
};
