import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";
import { Timestamp } from "firebase/firestore";
export const exporter = (fileName) => (records) => {
    jsonExport(records, {
        typeHandlers: {
            Object: (value /*, name*/) => {
                if (value instanceof Timestamp)
                    return value.toDate().toISOString();
                if (value instanceof Date)
                    return value.toISOString();
                return value;
            }
        }
    }, (err, csv) => {
        if (err)
            return console.error(err);
        return downloadCSV(csv, fileName);
    });
};
export const exportNestedList = (fileName) => (absoluteCollectionPath) => (records, fetchRelatedRecords, dataProvider, resource) => {
    // App automatically retrieves the records from the root resource instead of using filters to access the nested subcollection, as such we need to manually retrieve the data
    dataProvider
        .getMany(absoluteCollectionPath, { ids: records.map(({ id }) => id) })
        .then((nestedRecords) => {
        exporter(fileName)(nestedRecords.data, fetchRelatedRecords, dataProvider, resource);
    });
};
