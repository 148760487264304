import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import * as firebaseUi from "firebaseui";
import "firebaseui/dist/firebaseui.css";
export const StyledFirebaseAuth = ({ uiConfig, firebaseAuth, className, uiCallback }) => {
    const [userSignedIn, setUserSignedIn] = useState(false);
    const elementRef = useRef(null);
    useEffect(() => {
        if (!elementRef.current)
            return;
        let firebaseUiWidget;
        let unregisterAuthObserver;
        // firebase ui start in event loop to solve react 18 strict requirement.
        const timeout = setTimeout(() => {
            // Get or Create a firebaseUI instance.
            firebaseUiWidget =
                firebaseUi.auth.AuthUI.getInstance() || new firebaseUi.auth.AuthUI(firebaseAuth);
            if (uiConfig.signInFlow === "popup")
                firebaseUiWidget.reset();
            // We track the auth state to reset firebaseUi if the user signs out.
            unregisterAuthObserver = onAuthStateChanged(firebaseAuth, (user) => {
                if (!user && userSignedIn)
                    firebaseUiWidget.reset();
                setUserSignedIn(Boolean(user));
            });
            // Trigger the callback if any was set.
            if (uiCallback)
                uiCallback(firebaseUiWidget);
            // Render the firebaseUi Widget.
            firebaseUiWidget.start(elementRef.current, uiConfig);
        });
        return () => {
            clearTimeout(timeout);
            if (unregisterAuthObserver)
                unregisterAuthObserver();
            if (firebaseUiWidget)
                firebaseUiWidget.reset();
        };
    }, [uiConfig, userSignedIn, setUserSignedIn]);
    return _jsx("div", { className: className, ref: elementRef });
};
export default StyledFirebaseAuth;
