import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BooleanInput, Create, Edit, ImageField, NumberInput, required, SaveButton, SimpleForm, TextInput, Toolbar } from "react-admin";
import metadata from "$public/metadata.json";
import { RichTextInput } from "ra-input-rich-text";
import { AdvancedImageInput } from "$components/Inputs/AdvancedImageInput";
import { ScalingStrategy } from "$components/Inputs/AdvancedImageInput/AdvancedImageInputUtils";
export const StorePrivateItemsEdit = ({ variant, variantProps }) => {
    const FormWrapper = variant === "create" ? Create : Edit;
    const { __defaultValues = {} } = metadata.StorePrivateItem;
    return (_jsx(FormWrapper, { ...variantProps, children: _jsxs(SimpleForm, { defaultValues: __defaultValues, toolbar: _jsx(Toolbar, { children: _jsx(SaveButton, {}) }), children: [_jsx(TextInput, { name: "title", source: "title", validate: required(), fullWidth: true }), _jsx(RichTextInput, { name: "description", source: "description", validate: required(), fullWidth: true, height: 40 }), _jsx(NumberInput, { name: "coinsCost", source: "coinsCost", validate: required() }), _jsx(AdvancedImageInput, { width: 220, height: 150, name: "picture", source: "picture", accept: "image/png, image/jpeg, image/jpg", validate: required(), strategy: ScalingStrategy.MANUAL, children: _jsx(ImageField, { source: "src", title: "image" }) }), _jsx(BooleanInput, { name: "archived", source: "archived", options: {} })] }) }));
};
