import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Edit, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin";
import metadata from "$public/metadata.json";
import { getInputComponent } from "$utils/getInputComponent";
import { RichTextWithPlainCopy } from "$components/Inputs/RichTextWithPlainCopy";
export const UserTasksEdit = (editProps) => {
    const { __defaultValues = {} } = metadata.UserTask;
    const DateInput = getInputComponent("date", "finishDate");
    return (_jsx(Edit, { ...editProps, children: _jsxs(SimpleForm, { defaultValues: __defaultValues, children: [_jsx(TextInput, { source: "id", name: "id", label: "Submission id", fullWidth: true, disabled: true }), _jsx(ReferenceInput, { label: "User", source: "userId", name: "userId", reference: "Users", alwaysOn: true, children: _jsx(SelectInput, { name: "username", optionText: "username", fullWidth: true, disabled: true }) }), _jsx(ReferenceInput, { label: "Task", source: "taskId", name: "taskId", reference: "Tasks", alwaysOn: true, children: _jsx(SelectInput, { optionText: "name", name: "name", fullWidth: true, disabled: true }) }), _jsx(DateInput, { name: "finishDate", source: "finishDate", disabled: true }), _jsx(RichTextWithPlainCopy, { source: "comment" })] }) }));
};
