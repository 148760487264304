import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { BulkExportButton, BulkUpdateButton, Button, Datagrid, Link, TextField, useRecordContext, useResourceContext } from "react-admin";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ColorField } from "$components/ColorPicker";
import { ConditionalModifyButton } from "$components/List/ListActions/ConditionalModifyButton";
import ContentCreate from "@mui/icons-material/Create";
import { useCreatePath } from "ra-core";
const ValueBitTagsEditButton = () => {
    const createPath = useCreatePath();
    const resource = useResourceContext();
    const { id } = useRecordContext();
    return (_jsx(Button, { component: Link, to: createPath({
            resource: `${resource}/ValueBitTags`,
            type: "edit",
            id
        }), label: "", size: "medium", children: _jsx(ContentCreate, {}) }));
};
export const ValueBitTagsDatagrid = () => {
    return (_jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsxs(_Fragment, { children: [_jsx(BulkUpdateButton, { label: "Expose", data: { isHidden: false }, icon: _jsx(VisibilityOffIcon, {}), mutationMode: "pessimistic" }), _jsx(BulkUpdateButton, { label: "Hide", data: { isHidden: true }, icon: _jsx(VisibilityIcon, {}), mutationMode: "pessimistic" }), _jsx(BulkExportButton, {})] }), children: [_jsx(ValueBitTagsEditButton, {}), _jsx(TextField, { source: "name" }), _jsx(ColorField, { source: "color" }), _jsx(ConditionalModifyButton, { source: "visibility", label: "Visibility", tooltipPlacement: "content", tooltip: (record) => (record["isHidden"] ? "Show" : "Hide"), icon: null, changedData: (record) => ({ isHidden: !record["isHidden"] }) })] }));
};
