import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useWatch } from "react-hook-form";
import { AutocompleteInput, ReferenceInput } from "react-admin";
export const NestedListSearch = ({ reference, source, searchProps, searchField }) => {
    const [searchParams, setSearchParams] = useSearchParams(window.location.search);
    const idFromSearchParams = searchParams.get("id") ?? undefined;
    const dataField = useWatch({ name: source, defaultValue: idFromSearchParams });
    useEffect(() => {
        // synchronize query search params with form state
        setSearchParams(dataField ? { id: dataField } : {});
    }, [dataField]);
    return (_jsx(ReferenceInput, { name: source, source: source, reference: reference, 
        // TODO: workaround for fields with missing searchField
        sort: searchField ? { field: searchField, order: "ASC" } : undefined, ...searchProps, children: _jsx(AutocompleteInput, { name: source, defaultValue: idFromSearchParams, sx: {
                /* material ui reserves space for helper texts and form validation errors,
                   but we do not need it since we do not validate this input nor do we display any helper text */
                marginBottom: "-20px"
            }, optionText: (record) => record[searchField] ?? `#${record.id}`, filterToQuery: searchField ? (q) => ({ [searchField]: q }) : undefined, variant: "outlined" }) }));
};
