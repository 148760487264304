import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { DateField, EditButton, FilterList, FilterListItem, FilterLiveSearch, List, TextField } from "react-admin";
import { BooleanStateAction, exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { ProgressField } from "$components/ProgressField/ProgressField";
import { ConditionalModifyButton } from "$components/List/ListActions/ConditionalModifyButton";
import { StorePublicItemsDatagrid } from "$components/Entities/StorePublicItem/StorePublicItems.datagrid";
import { FilterSidebar } from "$components/FilterSidebar";
export const StorePublicItems = (props) => {
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("StorePublicItems"), filterDefaultValues: { archived: false, processed: false }, aside: _jsxs(FilterSidebar, { children: [_jsx(FilterLiveSearch, { source: "title", alwaysOn: true, placeholder: "Title" }), _jsxs(FilterList, { label: "Archive status", icon: "", children: [_jsx(FilterListItem, { label: "Archived", value: { archived: true } }), _jsx(FilterListItem, { label: "Non-Archived", value: { archived: false } })] }), _jsxs(FilterList, { label: "Processing status", icon: "", children: [_jsx(FilterListItem, { label: "Processed", value: { processed: true } }), _jsx(FilterListItem, { label: "Non-Processed", value: { processed: false } })] })] }), ...props, children: _jsxs(StorePublicItemsDatagrid, { children: [_jsx(EditButton, { label: "", size: "medium" }), _jsx(TextField, { source: "title" }), _jsx(ProgressField, { sourceValue: "coinsRaised", sourceTotal: "coinsCost", label: "Raised Coins" }), _jsx(DateField, { source: "gatheredDate", emptyText: "NOT GATHERED", label: "Status" }), _jsx(ConditionalModifyButton, { source: "visibility", label: "Visibility", tooltipPlacement: "content", tooltip: (record) => (record["archived"] ? "Unarchive" : "Archive"), icon: null, changedData: (record) => ({ archived: !record["archived"] }) }), _jsx(BooleanStateAction, { source: "processed" })] }) }));
};
