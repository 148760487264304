import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useAuthProvider } from "react-admin";
export const RequireSuperAdmin = ({ children }) => {
    const auth = useAuthProvider();
    const [isAuthorized, setIsAuthorized] = useState(false);
    useEffect(() => {
        auth.getPermissions(null).then((permissions) => {
            setIsAuthorized(Boolean(permissions.roles?.includes("superuser")));
        });
    }, [auth]);
    return _jsx(_Fragment, { children: isAuthorized && children });
};
