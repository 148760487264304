import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { useEffect } from "react";
import { Datagrid, DeleteWithConfirmButton, EditButton, useListContext, useRecordContext } from "react-admin";
import { renderTextFields } from "$components/List/ListData/ListData.utils";
import { ListBulkActions } from "$components/List/ListBulkActions";
export const NestedEditButton = ({ absoluteCollectionPath, resourceName, ...props }) => {
    const record = useRecordContext();
    const fullDocumentPath = `${absoluteCollectionPath}/${record.id}`;
    return (_jsx(EditButton, { ...props, resource: resourceName, record: { ...record, id: fullDocumentPath } }));
};
const DEFAULT_CONFIRM_CONTENT = "IMPORTANT: It is always better to hide items than to delete them. " +
    "Deleting an item that the players interacted with might cause unexpected issues.";
const DEFAULT_CONFIRM_TITLE = "Are you sure you want to delete this item? You can’t undo this action.";
export const NestedDeleteButton = ({ absoluteCollectionPath, confirmContent = DEFAULT_CONFIRM_CONTENT, confirmTitle = DEFAULT_CONFIRM_TITLE, ...props }) => {
    const record = useRecordContext();
    const pathSegments = absoluteCollectionPath.split("/");
    const [parentResource] = pathSegments;
    const pathWithoutParent = pathSegments.slice(1).join("/");
    return (_jsx(DeleteWithConfirmButton, { resource: parentResource, record: { ...record, id: `${pathWithoutParent}/${record.id}` }, confirmContent: confirmContent, confirmTitle: confirmTitle, ...props }));
};
const NestedListData = ({ absoluteCollectionPath, isEditable = true, isRemovable = true, resourceName, model, customConfirmContent, customConfirmTitle }) => {
    if (!resourceName) {
        resourceName = model;
    }
    const { data, refetch, isFetching, filter } = useListContext();
    useEffect(() => {
        // There is an issue with list context filters being updated slower than refetch being called
        setTimeout(() => refetch(), 0);
    }, [filter]);
    return (_jsxs(Datagrid, { cellPadding: 0, isLoading: isFetching, data: data, bulkActionButtons: _jsx(ListBulkActions, {}), children: [isEditable && (_jsx(NestedEditButton, { label: "", size: "small", absoluteCollectionPath: absoluteCollectionPath, resourceName: resourceName })), isRemovable && (_jsx(NestedDeleteButton, { label: "", size: "small", absoluteCollectionPath: absoluteCollectionPath, confirmContent: customConfirmContent, confirmTitle: customConfirmTitle })), data && renderTextFields(model)] }));
};
export default NestedListData;
