import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { AutocompleteInput, BulkExportButton, BulkUpdateButton, Datagrid, DateField, FilterList, FilterListItem, FilterLiveSearch, List, NumberField, ReferenceField, ReferenceInput, TextField } from "react-admin";
import { BooleanStateAction, exporter, ListActions } from "$components/List/ListActions";
import { ListEmpty } from "$components/List/ListEmpty";
import { Pagination } from "$components/Pagination";
import { UserCell } from "$components/TableCells/UserCell";
import { ConditionalModifyButton } from "$components/List/ListActions/ConditionalModifyButton";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { FilterSidebar } from "$components/FilterSidebar";
export const StoreClaimedPrivateItems = (props) => {
    return (_jsx(List, { pagination: _jsx(Pagination, {}), empty: _jsx(ListEmpty, {}), actions: _jsx(ListActions, {}), exporter: exporter("StoreClaimedPrivateItem"), filters: [
            _jsx(ReferenceInput, { label: "User", source: "userId", reference: "Users", alwaysOn: true, children: _jsx(AutocompleteInput, { optionText: "username", filterToQuery: (q) => ({ username: q }) }) })
        ], filterDefaultValues: { refunded: false, processed: false }, sort: { field: "claimedDate", order: "DESC" }, aside: _jsxs(FilterSidebar, { children: [_jsx(FilterLiveSearch, { source: "title", alwaysOn: true, placeholder: "Title" }), _jsxs(FilterList, { label: "Refund status", icon: "", children: [_jsx(FilterListItem, { label: "Refunded", value: { refunded: true } }), _jsx(FilterListItem, { label: "Non-Refunded", value: { refunded: false } })] }), _jsxs(FilterList, { label: "Processing status", icon: "", children: [_jsx(FilterListItem, { label: "Processed", value: { processed: true } }), _jsx(FilterListItem, { label: "Non-Processed", value: { processed: false } })] })] }), ...props, children: _jsxs(Datagrid, { cellPadding: 0, bulkActionButtons: _jsxs(_Fragment, { children: [_jsx(BulkUpdateButton, { label: "Refund", data: { refunded: true, processed: true }, icon: _jsx(CurrencyExchangeIcon, {}), mutationMode: "pessimistic" }), _jsx(BulkUpdateButton, { label: "Mark as processed", data: { processed: true }, icon: _jsx(CurrencyExchangeIcon, {}), mutationMode: "pessimistic" }), _jsx(BulkExportButton, {})] }), children: [_jsx(ReferenceField, { reference: "StorePrivateItems", source: "itemId", children: _jsx(TextField, { source: "title", label: "Store Item Title" }) }), _jsx(UserCell, { source: "userId" }), _jsx(NumberField, { source: "coinsPaid" }), _jsx(DateField, { source: "claimedDate" }), _jsx(ConditionalModifyButton, { source: "refundStatus", label: "Refund Status", tooltipPlacement: "content", tooltip: "Refund", icon: null, changedData: { refunded: true, processed: true }, isDisabled: (record) => Boolean(record["refunded"]) }), _jsx(BooleanStateAction, { source: "processed" })] }) }));
};
